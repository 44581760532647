import { createStaticRanges as reactCreateStaticRanges } from "react-date-range";
import moment from "moment";
import i18n from "../../../i18n";

export const t = i18n.getFixedT(null, "dashboardNavbar", "dateRanges")

export const defaultRanges = () => {
    return [
    {
        label: t("today"),
        range: () => ({
            startDate: moment().startOf("day").toDate(),
            endDate: moment().endOf("day").toDate()
        })
    },
    {
        label: t("yesterday"),
        range: () => ({
            startDate: moment().subtract(1, "days").startOf("day").toDate(),
            endDate: moment().subtract(1, "days").startOf("day").toDate(),
        })
    },
    {
        label: t("last7days"),
        range: () => ({
            startDate: moment().subtract(6, "days").startOf("day").toDate(),
            endDate: moment().endOf("day").toDate()
        })
    },
    {
        label: t("last30days"),
        range: () => ({
            startDate: moment().subtract(29, "days").startOf("day").toDate(),
            endDate: moment().endOf("day").toDate()
        })
    },
    {
        label: t("thisMonth"),
        range: () => ({
            startDate: moment().startOf("month").toDate(),
            endDate: moment().endOf("day").toDate()
        })
    },
    {
        label: t("lastMonth"),
        range: () => ({
            startDate: moment().subtract(1, "months").startOf("month").toDate(),
            endDate: moment().subtract(1, "months").endOf("month").toDate()
        })
    },
    {
        label: t("last3months"),
        range: () => ({
            startDate: moment().subtract(90, "days").startOf("day").toDate(),
            endDate: moment().endOf("day").toDate()
        })
    }
  ];
};

export const createStaticRanges = () => {
  return reactCreateStaticRanges(defaultRanges())
}

export const labelToRange = {
    "today": ({
        startDate: moment().startOf("day").toDate(),
        endDate: moment().endOf("day").toDate()
    }),
    "yesterday": ({
        startDate: moment().subtract(1, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").startOf("day").toDate(),
    }),
    "last7days":  ({
        startDate: moment().subtract(6, "days").startOf("day").toDate(),
        endDate: moment().endOf("day").toDate()
    }),
    "last30days": {
        startDate: moment().subtract(29, "days").startOf("day").toDate(),
        endDate: moment().endOf("day").toDate()
    },
    "thisMonth":({
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("day").toDate()
    }),
    "lastMonth":({
        startDate: moment().subtract(1, "months").startOf("month").toDate(),
        endDate: moment().subtract(1, "months").endOf("month").toDate()
    }),
     "last3months":({
        startDate: moment().subtract(90, "days").startOf("day").toDate(),
        endDate: moment().endOf("day").toDate()
    })
};