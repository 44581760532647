import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";

const Select = ({
  options,
  value,
  changeHandler,
  maxWidth,
  minWidth = "175px",
  isMulti = false,
  components,
  closeMenuOnSelect = true,
  hideSelectedOptions = true,
  customStyles,
  onMenuClose
}) => {
  const styles = {
    container: (provided, state) => ({
      ...provided,
      border: "none"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "31px",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "0 8px",
      minHeight: "unset",
      background: "none !important"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "28px",
      minHeight: "unset",
      background: "none !important"
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "14px",
      background: "unset",
      "&:hover": {
        cursor: "pointer",
        color: "#FC6723",
        background: "#FFF3F0"
      }
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: "transparent",
      border: "0.5px solid #C2C2C2",
      borderRadius: "20px",
      maxWidth,
      minWidth,
      minHeight: "31px",
      boxShadow: "none",
      "&:hover": {
        border: "0.5px solid #FC6723"
      },
      "&:focus": {
        border: "0.5px solid #FC6723"
      }
    }),
    input: (provided, state) => ({
      ...provided,
      border: "none",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "0 5px",
      height: "31px",
      margin: "0"
    }),
    ...customStyles
  };

  return (
    <ReactSelect
      className="select-container"
      styles={styles}
      options={options}
      value={value}
      onChange={changeHandler}
      isMulti={isMulti}
      components={components}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      onMenuClose={onMenuClose}
    />
  );
};

export default Select;
