import React, { memo, useCallback, useRef } from 'react'
import ModalService from '../../../../services/ModalService'
import Modal from '../../../common/modals/Modal'
import ModalBody from '../../../common/modals/ModalBody'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from "../filters.module.scss"
import { Save, XCircle, X } from 'react-feather'
import Domain from '../../../common/Filters/Domain'
import Country from '../../../common/Filters/Country'
import ThreatGroup from '../../../common/Filters/ThreatGroup'
import Dates from '../../../common/Filters/Dates'
import { filtersActionCreator } from '../../../../store/modules/filters/action-creators'
import { blocksActionCreator } from '../../../../store/modules/blocks/action-creators'
import { filtersAction } from '../../../../store/modules/filters/types'
import LoaderWrapper from '../../../Loader/LoaderWrapper'

const iconSize = 16

const FiltersModal = () => {
  const { t } = useTranslation(["blocksReport", "dashboardNavbar", "common"])
  const dispatch = useDispatch()
  const filters  = useSelector((state) => state.filtersReducer.filters)
  const ref = useRef(null)

  const closeModal = () => {
    ModalService.close()
  }

  const clearFilters = useCallback(() => {
    ref.current.clear()
    dispatch(filtersActionCreator.setFilter(null, filtersAction.RESET))
  })

  const fetch = useCallback(async () => {    
    await dispatch(blocksActionCreator.updateBlockedIps(filters))
    closeModal()
  }, [filters])

  return (
    <Modal>
      <ModalBody>
        <LoaderWrapper fullScreen>
          <section className={styles.modal}>
            <nav>
              <h2 style={{ marginRight: '20px' }}>{t("filters", {ns: "common"})}</h2>
              <X size={iconSize} onClick={closeModal}>x</X>
            </nav>          
            <section className={styles.filters}>
              <Domain/>
              <Dates/>
              <ThreatGroup/>
              <Country ref={ref}/>            
            </section>
            <footer>
              <button onClickCapture={clearFilters}><XCircle size={iconSize} />Clear</button>
              <button onClick={fetch}><Save size={iconSize} />Save</button>
            </footer>
          </section>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  )
}

export default FiltersModal