import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PlusCircle } from "react-feather";
import styles from "./style.scoped.scss"
import { domainsActionCreator } from "../../../store/modules/domains/action-creators";
import { tagsActionCreator } from "../../../store/modules/tags/action-creators";
import { filtersActionCreator } from "../../../store/modules/filters/action-creators";
import { filtersAction } from "../../../store/modules/filters/types";
import { urlHelper } from "../../../utils/urlHelper";
import { validators } from "../../../utils/validators.js";
import Pages from "../Enums";
import { useTranslation } from "react-i18next";
import { track, TRACK_EVENTS } from "../../../services/tracking";

const { getHostname } = urlHelper();

const AddDomain = ({ setTitle, setPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [domainInput, setDomainInputValue] = useState("");
  const [validateDomainError, setValidateDomainError] = useState("");
  const { t } = useTranslation(["common", "onboarding"])
  const { validateDomain } = validators();
  useEffect(() => setTitle("Add a New Domain"));

  const addDomain = async () => {     
    const domain = getHostname(domainInput);
    if (!validateDomain(domain)) return setValidateDomainError(t("domainValidationError", { ns: "onboarding" }))
    const { data } = await dispatch(domainsActionCreator.addDomain(domain));
    if (data) {
      track(TRACK_EVENTS.BZAddNewDomain) 
      dispatch(domainsActionCreator.setNoDomains(false));
      await dispatch(tagsActionCreator.getTagsAndDomains(domain));
      history.push("/dashboard");
      setPage(Pages.COMPLETE_SETUP);
      const objectDomain = { label: domain, value: domain };
      dispatch(domainsActionCreator.addSelectedDomain(objectDomain));
      dispatch(domainsActionCreator.setSelectedDomain(objectDomain));
      dispatch(filtersActionCreator.setFilter(domain, filtersAction.SET_DOMAIN_FILTER));
      dispatch(domainsActionCreator.getBotzaDomains());
    } else setValidateDomainError(t("addDomainNetworkError", { ns: "onboarding" }));
  };

  return (
    <div className="height-92 body-container">
      <div className="ml-40 domain-address-container">
        <span className="fw-600 fs-14">{t("domain")} {t("address")}</span>
        <input className="display-block mt-5 width-50 fs-14 nunito enter-domain"
          value={domainInput}
          onChange={e => { setDomainInputValue(e.target.value) }}
          placeholder={t("enterYourDomain", {ns: "onboarding"})}
        />
        <label className="error">{validateDomainError}</label>
      </div>
      <div>
        <div className="position-absolute bottom-right m-20 flex align-center">
          <span className="color-base cursor-pointer mr-16 fs-14 back-btn" onClick={() => setPage(Pages.DOMAIN_SELECT)}>
            {t("back")}
          </span>
          <button className="fs-14" onClick={addDomain}>
            <PlusCircle size={16} style={{ marginRight: ".25rem" }} />
            {t("addDomain", { ns: "onboarding" })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDomain;
