import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Info } from "react-feather";
import ReactTooltip from "react-tooltip";
import Table from "./Table";
import styles from "./top.module.scss";
import { useTranslation } from "react-i18next";
const GB = "GB";
const UK = "UK";

const BlocksHeatmap = () => {
  const [chartData, setChartData] = useState(null);
  const blocksByCountry = useSelector(state => state.summaryReducer.summary.blocksByCountry);
  const totalBlocked = useSelector(state => state.summaryReducer.summary.summary.totalBlocked);
  const { t } = useTranslation("dashboard", { keyPrefix: "topCountries"});
  const createDatasets = () => {
    const dataSet = [];
    blocksByCountry.map((item, index) => {
      if (index >= 9 || item.totalBlocked <= 0) return;
      const percent = (item.totalBlocked / totalBlocked) * 100;
      dataSet.push({
        country: item.country === UK ? GB : item.country,
        blocked: item.totalBlocked,
        percent: isNaN(percent) ? 0 : percent
      });
    });
    setChartData(dataSet);
  };

  useEffect(createDatasets, [blocksByCountry]);

  return (
    <div>
      <ReactTooltip effect="solid" multiline type="light" />
      <div className={styles.top}>
        <div>
          <h4 className='fw-600'>{t("title")}</h4>
          <Info data-tip={t("tooltip")} />          
        </div>
        <div>
          <Table data={chartData} />
        </div>
      </div>
    </div>
  );
};

export default BlocksHeatmap;
