import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import "./style.scoped.scss"
import { useTranslation } from 'react-i18next'

const ClipboardCopy = ({ number, copyText, title, fullWidth = true, css, copyHtml, dataContainerCSS }) => {
  const [showCopyDialog, setCopyDialog] = useState(false)
  const [randomID, setRandomID] = useState(String(Math.random()))
  const { t } = useTranslation("common");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText)
    setCopyDialog(true)
    setTimeout(() => {
      setCopyDialog(false)
    }, 300)
  }
  return (
    <div className={css}>
      <ReactTooltip type="light" id={randomID} />
      <div className={dataContainerCSS|| "flex"}>
        {number && <p className="round-shadow inline-block fs-14">{number}</p>}
        {title && <label className="fs-14">{title}</label>}
        <div data-tip={copyText} data-for={randomID} className="plugin-key" style={{ width: fullWidth ? 'unset' : '96px' }}>
          {copyText}
        </div>
        <div className="copy__section position-relative inline-block">
          <label className="text-color-base ml-8 fs-14 cursor-pointer" onClick={copyToClipboard}>
            {copyHtml || t("copy")}
          </label>
          {showCopyDialog && <div className="copy-dialog">{t("copied")}!</div>}
        </div>
      </div>
    </div>
  )
}

export default ClipboardCopy
