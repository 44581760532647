import ThreatGroups from "./ThreatGroups";

export const threatGroups = {
  INVALID_BOT_ACTIVITY: "Invalid Bot Activity",
  INVALID_MALICIOUS_ACTIVITY: "Invalid Malicious Activity"
};

export const colors = {
  [threatGroups.INVALID_MALICIOUS_ACTIVITY]: "#FF4B21",
  [threatGroups.INVALID_BOT_ACTIVITY]: "#FF9210"
};

export const insightCategoriesTable = {
  column: ["threatGroups.title", "threatTypes.title"],
  rows: [
    {
      color: colors[threatGroups.INVALID_BOT_ACTIVITY],
      group: "invalidBotActivity",
      types: ["threatTypes.categories.automationTools.title", "threatTypes.categories.scrapers.title", "threatTypes.categories.accountTakeover.title"]
    },
    {
      color: colors[threatGroups.INVALID_MALICIOUS_ACTIVITY],
      group: "invalidMaliciousActivity",
      types: ["threatTypes.categories.falseRepresentation.title", "threatTypes.categories.excessiveRateLimit.title", "threatTypes.categories.networkAnomalies.title"]
    }
  ]
};

export const defaultState = {
  backgroundColor: "#FDFBFA",
  chart: {
    type: "area"
  },
  dataLabels: {
    enabled: true
  },
  bar: {
    width: "20"
  },
  hAxis: {
    format: "#'%'",
    minValue: 0,
    maxValue: 100,
    gridlines: { count: 4 }
  },
  vAxis: {
    textStyle: {
      fontSize: 12,
      color: "black"
    }
  },
  chartArea: {
    width: "50%",
    left: 120
  },
  legend: { position: "none" },
  annotations: {
    textStyle: {
      fontSize: 12,
      auraColor: "transparent",
      fontWeight: 400,
      lineHeight: 16,
      color: "black"
    },
    alwaysOutside: true,
    stem: {
      color: "transparent"
    }
  },
  tooltip: {
    trigger: "none"
  },
  enableInteractivity: false
};
