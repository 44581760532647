import React, { memo } from "react";
import BlockedIpsNavbar from "../../../components/BlocksReport/Filters";
import BlockedIps from "../../../components/BlocksReport/BlockedIps";
import LoaderWrapper from "../../../components/Loader/LoaderWrapper";
import styles from "../blocks-report.module.scss";

const MobileView = memo(({ className }) => (
  <LoaderWrapper fullScreen className={styles.loader}>
    <BlockedIpsNavbar />
    <div className={className}>
      <BlockedIps/>
    </div>
  </LoaderWrapper>
))


export default MobileView;
