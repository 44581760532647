import React, { memo, useEffect, useState } from "react";
import { Download } from "react-feather";
import SearchInput from "../../../globals/SearchInput";
import { useTranslation } from "react-i18next";
import { blocksActionCreator } from "../../../../store/modules/blocks/action-creators";
import styles from "../filters.module.scss";
import ThreatGroupFilter from "../../../common/Filters/ThreatGroup";
import CountryFilter from "../../../common/Filters/Country";
import { useDispatch, useSelector } from "react-redux";

const DesktopView = memo(({ searchInput, clearSearchcb, onChangecb }) => {
  const { t } = useTranslation(["blocksReport", "common"]);
  const dispatch = useDispatch();

  const downloadCSV = () => {
    dispatch(blocksActionCreator.getCSV());
  };

  return (
    <div className={styles.blocksFilters}>
      <div className={styles["filters-elements"]}>
        <section>
          <h2 style={{ marginRight: "20px" }}>{t("filters", { ns: "common" })}</h2>
          <ThreatGroupFilter />
          <CountryFilter />
        </section>
        <section>
          <SearchInput searchInput={searchInput} clearSearchcb={clearSearchcb} onChangecb={onChangecb} />
          <button onClick={downloadCSV}>
            <Download size={16} />
            <label>{t("download")} CSV</label>
          </button>
        </section>
      </div>
    </div>
  );
});

export default DesktopView;
