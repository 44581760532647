import axios from "axios";
import { useLocalStorageUser } from "../utils/User";

export const useAxiosInstance = () => {
  const timeOut = 15000;
  const instance = () =>
    axios.create({
      baseURL: process.env.REACT_APP_BOTZAPPING_URL,
      timeout: timeOut,
      headers: {
        Authorization: "Bearer " + useLocalStorageUser().getToken()
      }
    });

  const clickCeaseInstance = () =>
    axios.create({
      baseURL: process.env.REACT_APP_CLICKCEASE_URL,
      timeout: timeOut,
      headers: {
        Authorization: "Bearer " + useLocalStorageUser().getToken()
      },
      withCredentials: true
    });

  return {
    instance,
    clickCeaseInstance
  };
};
