import { isNil } from "lodash";

export const urlHelper = () => {
  const getHostname = url => {
    const matches = url.match(/^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im);
    return matches && matches[1];
  };

  const getParams = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    return params;
  };

  return {
    getHostname,
    getParams
  };
};
