import { X } from "react-feather";
import "./style.scoped.scss";
import { useTranslation } from "react-i18next";
import { Search } from "react-feather";

const SearchInput = ({ css, searchInput, onChangecb, clearSearchcb, style, placeholder, icon, showClear, clearIconSize = 14, focus = false }) => {
  const { t } = useTranslation("common")
  const placeHolderText = placeholder || placeholder === "" ? placeholder : t("search...")
  return (
    <div className={`search margin-left-auto ${css ? css.wrapper : ""}`} style={style} >
      { icon !== false && <span>{ icon || <Search size={13} className="icon"/> }</span> }
      <input placeholder={placeHolderText} value={searchInput} onChange={onChangecb} autoFocus={focus}/>
      { (showClear || searchInput) && <X size={clearIconSize} className={`clear-icon cursor-pointer ${css ? css["clear-icon"] : ""}`} onClick={clearSearchcb} />}
    </div>
  );
};

export default SearchInput;
