import React, { useCallback, forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { periods } from '../DashboardNavbar/consts'
import DatePicker from '../../globals/DatePicker'
import { useTranslation } from 'react-i18next'
import { filtersAction } from '../../../store/modules/filters/types'
import { filtersActionCreator } from '../../../store/modules/filters/action-creators'
import { formatDate } from '../../../utils/date'

const Dates = forwardRef((_, ref) => {
  const [periodFilter, setPeriodFilter] = useState(periods)
  const fromDateFilter = useSelector(state => state.filtersReducer.filters["from-date"])
  const toDateFilter = useSelector(state => state.filtersReducer.filters["to-date"])
  const { t } = useTranslation("dashboardNavbar")
  const dispatch = useDispatch()

  const setPeriod = useCallback(([period]) => {
    setPeriodFilter(period)
    dispatch(filtersActionCreator.setFilter(formatDate(period.startDate), filtersAction.SET_PERIOD_FILTER))
    dispatch(filtersActionCreator.setFilter(formatDate(period.endDate), filtersAction.SET_PERIOD_FILTER_TO_DATE))
  })

  const refresh = () => {
    setPeriodFilter(periods)
  }

  useImperativeHandle(ref, () => ({refresh}))

  const setLocalFilters = () => setPeriodFilter({ startDate: fromDateFilter, endDate: toDateFilter, key: "rollup" })

  useEffect(setLocalFilters, [fromDateFilter, toDateFilter])

  return (
    <div>
      <label>{t("dataRangeFilter")}</label>
      <DatePicker onChange={setPeriod} value={periodFilter} />
    </div>
  );
});

export default Dates;