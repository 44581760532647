import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { createStaticRanges } from "./consts";
import { Check } from "react-feather";
import { formatDate } from "../../../utils/date";
import { addMonths, differenceInMonths } from "date-fns";
import "./datepicker.scss";
import { filtersAction } from "../../../store/modules/filters/types";
import { filtersActionCreator } from "../../../store/modules/filters/action-creators";
import { useDispatch, useSelector } from "react-redux";
import ModalService from "../../../services/ModalService";
import { StaticRangesModal } from "./staticRangesModal";

const openDatesSelection = (setSelectedRange) => ModalService.push(StaticRangesModal, {setSelectedRange})

const DatePicker = ({ value, onChange}) => {
  const isMobile = useSelector(state => state.generalReducer.isMobileResolution)
  const dispatch = useDispatch();
  const [pickerState, setPickerState] = useState(false);
  const [inputLabel, setInputLabel] = useState("Last 7 Days");
  const [ranges, setRanges] = useState([{ startDate: new Date(), endDate: new Date(), key: "rollup" }]);
  const staticRanges = createStaticRanges();

  const handleShowPickerClick = () => {
    isMobile? openDatesSelection(setSelectedRange): toggleModal(!pickerState);
  }

  const setDateFilter = () => {
    changeInputLabel();
    setPickerState(!pickerState);
    onChange(ranges);
  };

  const changeInputLabel = (defRange = ranges[0]) => {
    const defaultRange = {
      startDate: defRange?.startDate > defRange?.endDate ? defRange?.endDate : defRange?.startDate,
      endDate: defRange?.startDate > defRange?.endDate ? defRange?.startDate : defRange?.endDate
    };
    const range = staticRanges.find(item => {
      const { startDate, endDate } = item.range();
      return startDate?.getDate() == defaultRange?.startDate?.getDate() && endDate?.getDate() == defaultRange?.endDate?.getDate();
    });

    if (range && (defaultRange?.endDate - defaultRange?.startDate) / (1000 * 60 * 60 * 24) > 32) {
      setInputLabel("Last 3 Months");
      dispatch(filtersActionCreator.setFilter(true, filtersAction.SET_LAST_3_MONTHS));
      return;
    } // TEMP SOLUTION FOR 3 MONTHS
    dispatch(filtersActionCreator.setFilter(false, filtersAction.SET_LAST_3_MONTHS));

    setInputLabel(
      range ? range.label : `${formatDate(defaultRange?.startDate)} - ${formatDate(defaultRange?.endDate)}`
    );
  };

  const eventHandler = e => {
    const date_picker = document.getElementById("date-picker-modal-container");
    if (date_picker && !date_picker.contains(e.target)) {
      toggleModal(false);
    }
  };
  const toggleModal = value => {
    const el = document.getElementById("root");
    setPickerState(value);

    if (value) el.addEventListener("click", eventHandler);
    else el.removeEventListener("click", eventHandler);
  };

  const rangesChange = ranges => {
    setRanges([ranges.rollup]);
  };

  const setSelectedRange = (period, isCustom=false) => {
    if(isCustom){
      toggleModal(true);
      return;
    } 
    rangesChange(period);
    changeInputLabel(period);
    onChange(period);
   }

  useEffect(() => {
    const range = { startDate: new Date(value.startDate), endDate: new Date(value.endDate), key: "rollup" };
    setRanges([range]);
    changeInputLabel(range);
  }, [value]);

  return (
    <div id="date-picker-modal-container">
      <input className="show-picker-button" value={inputLabel} onClick={handleShowPickerClick} readOnly/>
      {pickerState && (
        <div className="date-picker-container">
          <DateRangePicker
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            rangeColors={["#f87708"]}
            ranges={ranges}
            onChange={rangesChange}
            staticRanges={staticRanges}
            inputRanges={[]}
            minDate={addMonths(new Date(), -3)}
            maxDate={new Date()}
          />
          <div className="action-line">
            <button onClick={setDateFilter}>
              <Check size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
