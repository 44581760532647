import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Info, Trash2 } from "react-feather";
import ReactTooltip from "react-tooltip";
import Toggle from "react-toggle";
import { domainsActionCreator } from "../../../store/modules/domains/action-creators";
import { tagsActionCreator } from "../../../store/modules/tags/action-creators";
import { filtersActionCreator } from "../../../store/modules/filters/action-creators";
import { filtersAction } from "../../../store/modules/filters/types";
import ClipboardCopy from "../../globals/ClipboardCopy";
import SearchInput from "../../globals/SearchInput";
import ModalService from "../../../services/ModalService";
import DeleteModal from "../DeleteModal";
import "./style.scoped.scss";
import "react-toggle/style.css"; // for ES6 modules
import { TOOLTIPS } from "./consts";
import { track, TRACK_EVENTS } from "../../../services/tracking";

const ProtectionSetupTable = () => {
  const dispatch = useDispatch();

  const filterBotzaDomains = useSelector(state => state.domainReducer.filterBotzaDomains);
  const botzaDomains = useSelector(state => state.domainReducer.botzaDomains);
  const [searchInput, setInputValue] = useState("");
  const [asc, setSortOrder] = useState(true);

  const sortDomain = () => {
    setSortOrder(!asc);
    dispatch(domainsActionCreator.setSortDomains(asc));
  };
  const search = e => {
    setInputValue(e.target.value);
    dispatch(domainsActionCreator.setBotzaDomainFilter(e.target.value));
  };
  const openDeleteDomainModal = domain => {
    track(TRACK_EVENTS.BZClickDeleteDomain, { domain });
    ModalService.open(DeleteModal, { deleteDomain, domain });
  };
  const deleteDomain = domain => {
    track(TRACK_EVENTS.BZConfirmDeletDomain, { domain });
    dispatch(domainsActionCreator.deleteTags([domain]));
    dispatch(domainsActionCreator.setSelectedDomain(filterBotzaDomains[0]));
    setInputValue("");
    ModalService.close();
  };
  const toggleChange = async domain => {
    track(!!domain.isPaidMarketingEnabled ? TRACK_EVENTS.BZDisabledPaidMArketing : TRACK_EVENTS.BZEnabledPaidMarketing, { domain });
    domain.isPaidMarketingEnabled = !domain.isPaidMarketingEnabled;
    await dispatch(domainsActionCreator.updatePaidMarketing(domain));
  };

  const monitoringToggleChange = async domain => {
    domain.isMonitoring = !domain.isMonitoring;
    await dispatch(domainsActionCreator.updateMonitoring(domain));
  };

  const clearSearch = () => {
    setInputValue("");
    dispatch(domainsActionCreator.setBotzaDomainFilter(""));
  };

  const setDomainFilter = domain => {
    dispatch(domainsActionCreator.setSelectedDomain({ label: domain, value: domain }));
    dispatch(filtersActionCreator.setFilter(domain, filtersAction.SET_DOMAIN_FILTER));
  };

  useEffect(async () => {
    const secretKeyResponse = await dispatch(tagsActionCreator.getSecretKey());
    dispatch(domainsActionCreator.getBotzaDomains());
  }, []);

  return (
    <section>
      <ReactTooltip effect="solid" multiline type="light" />
      <div className="flex">
        <SearchInput searchInput={searchInput} clearSearchcb={clearSearch} onChangecb={search} />
      </div>
      <header className="fs-14">
        <div className="col" style={{ width: "324px" }}>
          <span className={`arrow-${asc ? "down" : "up"} arrow-down cursor-pointer fw-600`} onClick={sortDomain}>
            Domain
          </span>
        </div>
        <div className="col fw-600" style={{ width: "184px" }}>
          Authentication Key
        </div>
        <div className="col fw-600" style={{ width: "184px" }}>
          Domain Key
        </div>
        <div className="col" style={{ width: "180px" }}>
          <Info className="info-icon" data-tip={TOOLTIPS.MONITORING} />
          <label className="fw-600 info-label">Monitoring Mode</label>
        </div>
        <div className="col" style={{ width: "180px" }}>
          <Info className="info-icon" data-tip={TOOLTIPS.PAID_MARKETING} />
          <label className="info-label fw-600">Paid Marketing</label>
        </div>
      </header>
      {filterBotzaDomains?.map((domain, index) => (
        <div className="row" key={index} style={{ minWidth: "1280px" }}>
          <div className="col flex-auto fs-14" style={{ width: "324px" }}>
            <span className="domain-title">{domain.domain}</span>
          </div>
          <div className="col flex-auto" style={{ width: "184px" }}>
            <ClipboardCopy fullWidth={false} copyText={domain.apiKey} />
          </div>
          <div className="col flex-auto" style={{ width: "184px" }}>
            <ClipboardCopy fullWidth={false} copyText={domain.hash} />
          </div>
          <div className="col flex-auto" style={{ width: "180px" }}>
            <label>
              <Toggle className="clickcease ml-15" defaultChecked={domain.isMonitoring} value={domain.isMonitoring} icons={false} onChange={() => monitoringToggleChange(domain)} />
            </label>
          </div>
          <div className="col flex-auto" style={{ width: "180px" }}>
            <label>
              <Toggle
                disabled={botzaDomains.length === 1}
                className="clickcease ml-15"
                defaultChecked={domain.isPaidMarketingEnabled}
                value={domain.isPaidMarketingEnabled}
                icons={false}
                onChange={() => toggleChange(domain)}
              />
            </label>
          </div>
          <div className="flex" style={{ width: "100%", justifyContent: "flex-end" }}>
            <div className="col flex-auto">
              <NavLink className="dashboard color-base text-decoration-none fs-14 mr-90 fw-600" onClick={() => setDomainFilter(domain.domain)} to="/dashboard">
                Open Dashboard
              </NavLink>
            </div>
            <div className="col flex-auto cursor-pointer ml-20 delete-container" style={{ width: "" }} onClick={() => openDeleteDomainModal(domain)}>
              <Trash2 className="trash" />
              <label className="color-base trash-label cursor-pointer fs-14 fw-600">Delete</label>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default ProtectionSetupTable;
