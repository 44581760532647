import { tagsAction } from "./types";
import { tagsApi } from "../../../services/tagService";
import { requestHandler } from "../../../utils/requestHandler";

export const tagsActionCreator = {
  setTags: tags => ({
    type: tagsAction.SET_TAGS,
    payload: tags
  }),
  setSecretKey: secretKey => ({
    type: tagsAction.SET_SECRET_KEY,
    payload: secretKey
  }),
  setDomainsSelect: domains => ({
    type: tagsAction.SET_DOMAIN_SELECT,
    payload: domains
  }),
  setCurrentDomain: domain => ({
    type: tagsAction.SET_CURRENT_DOMAIN,
    payload: domain
  }),
  setCurrentTag: tag => ({
    type: tagsAction.SET_CURRENT_TAG,
    payload: tag
  }),

  getTagsAndDomains: newDomain => async dispatch => {
    const { data: tags } = await requestHandler(dispatch, tagsApi.getTags, null);
    if (tags) {
      const uniqTags = [...new Map(tags.map(tag => [tag.value, tag])).values()];
      const domainsSelect = uniqTags.map(tag => ({
        value: tag.searchId,
        label: tag.value
      }));

      const currentDomain = newDomain ? domainsSelect.find(item => item.label === newDomain) : domainsSelect[0];

      dispatch(tagsActionCreator.setTags(uniqTags));
      dispatch(tagsActionCreator.setDomainsSelect(domainsSelect));

      const initTag = uniqTags.filter(tag => tag.searchId === currentDomain.value);
      dispatch(tagsActionCreator.setCurrentDomain(currentDomain));
      dispatch(tagsActionCreator.setCurrentTag(...initTag));
    }
  },
  getSecretKey: () => async dispatch => {
    const { data: networkData } = await requestHandler(dispatch, tagsApi.getSecretKey, null);
    if (networkData) dispatch(tagsActionCreator.setSecretKey(networkData.secretKey));
  }
};