import styles from "./summary.module.scss"
import { useState } from "react"
import { useTranslation } from "react-i18next"
export const SummaryCard = ({ mainTitle, secondaryTitle, mainContent, secondaryContent, expanded, footer, readMore }) => {
  const { t } = useTranslation("common")
  const [expand, toggleExpand] = useState(false);
  const more = t('more')
  const learnMore = `${t('learn')} ${more}`
  const showLess = `${t('showLess')}`
  return (
    <div className={ styles.summary_card }> 
      <section className={ styles.header}>
        <div>{ mainTitle }</div>
        <div className={styles.secondary_title}>{ secondaryTitle }</div>
      </section>      
      <section className={styles.body}>
        <h1>{ secondaryContent }</h1> 
        <div className={`${styles.main_content} ${expand && styles.heigth_auto}`}>{ mainContent }</div>
        { expand && <div class="align-self-streach">{expanded}</div> }          
      </section>   
      <section className={ styles.footer }>
        {footer} 
        { expanded && <div className={ styles.read_more } onClick={() => toggleExpand(!expand)} >{readMore || (expand ? showLess : learnMore) }</div> }        
      </section>        
    </div>
  )
}