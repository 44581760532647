import { useAxiosInstance } from "./axiosInstance";
import { useLocalStorageUser } from "../utils/User";
const instance = useAxiosInstance().clickCeaseInstance;

export const domainsApi = {
  getDomains() {
    return instance().get(`/BotZappingDomain/${useLocalStorageUser().getId()}`);
  },
  getDomainsLimits() {
    return instance().get(`/BotZappingMetadata/${useLocalStorageUser().getId()}`);
  },
  updateDomains(domains) {
    return instance().put(`/BotZappingDomain`, domains);
  },
  addDomain(domain) {
    return instance().post(`/BotZappingDomain`, domain);
  }
};
