import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Info } from "react-feather";
import ReactTooltip from "react-tooltip";
import DonutChart from "../../../Charts/Donut";
import DonutTotals from "./DonutTotals";
import styles from "./threat.module.scss";
import { colors, threatGroups } from "../consts";
import { useTranslation } from "react-i18next";

const ThreatGroups = () => {
  const { t } = useTranslation(["dashboard", "common"]);
  const insights = useSelector(state => state.summaryReducer.summary.insights);
  const [chartData, setChartData] = useState(null);
  const [donutTotals, setDonutTotals] = useState([]);
  const invalidBotActivityTitle = t("invalidBotActivity", { ns: "common" });
  const invalidMaliciousActivity = t("invalidMaliciousActivity", { ns: "common" });
  const createDatasets = () => {
    const dataSet = { 
      donutChartData: {
        series: [],
        labels: [],
        colors: [] 
      },
      donutTotals: []
    }    

    if (insights && insights.length) {
      dataSet.donutTotals.totalBlocked = insights.totalBlocked;
      const reduced = insights.reduce((accumulator, current) => {
        const groupTitle = current.threatGroup === threatGroups.INVALID_BOT_ACTIVITY ? invalidBotActivityTitle : invalidMaliciousActivity
        accumulator.donutChartData.labels.push(groupTitle);
        accumulator.donutChartData.series.push(current.totalBlocked);
        accumulator.donutChartData.colors.push(colors[current.threatGroup])
        accumulator.donutTotals.push({ threatGroup: groupTitle, totalBlocked: current.totalBlocked })
        return accumulator
      }, dataSet)
      setChartData(reduced.donutChartData)
      setDonutTotals(reduced.donutTotals)
    }    
  };

  useEffect(createDatasets, [insights]);

  return (
    <div>
      <ReactTooltip className={styles.tooltip} effect="solid" multiline id="tooltip-threat-group" type="light">
        <section>
          <div>
            <b>{invalidBotActivityTitle}</b>
            {t("insights.threatGroups.invalidBotActivity.tooltip")}
          </div>
          <div>
            <b>{invalidMaliciousActivity}</b>
            {t("insights.threatGroups.invalidMaliciousActivity.tooltip")}
          </div>
        </section>
      </ReactTooltip>
      <div className={styles.threat_groups}>
        <div>
          <h4 className="fw-600">{t("insights.threatGroups.title")}</h4>
          <Info data-tip data-for="tooltip-threat-group" />
        </div>
        <div>
          <DonutChart data={chartData} />
          <DonutTotals data={donutTotals} totalTitle={t("insights.threatGroups.totalTitle")} />
        </div>
      </div>
    </div>
  );
};

export default ThreatGroups;
