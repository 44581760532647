export const LOCAL_STORAGE_USER_KEY = "cc_lsu";
export const LOCAL_STORAGE_USER_KEY_TEMP = "cc_lsu_temp";
export const DEFAULT_LANGUAGE = "en";
export const SECRET = "Gass Monkey";
export const PLUGIN_URL = "https://wordpress.org/plugins/clickcease-click-fraud-protection/";
export const WORDPRESS_PLUGIN_PARTIAL_URL = "/wp-admin/plugin-install.php?tab=plugin-information&plugin=clickcease-click-fraud-protection&TB_iframe=true&width=772&height=630";

export const ROUTES = {
  dashboard: "/dashboard",
  onboarding: "/onboarding",
  login: "/login"
};

export const apps = {
  PAID_MARKET: {
    key: "paidMarketing",
    translate: true,
    class: "color-base"
  },
  BOT_ZAPPING: "Bot Zapping"
};

export const emailValidationError = "The email address you've entered is invalid. Please try again.";
export const loginError = "Failed to login, please contact support.";

export const DDMMYYYYHHMMSS = "dd-MM-yyyy HH:mm:ss";
export const UTC = "UTC";

export const DEFAULT_TABLE_PAGE_SIZE = 100;
