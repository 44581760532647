import { useState } from "react";
import { useCallback } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Columns } from "./Columns";
import { useEffect } from "react";
import { tableCustomStyles } from "./tableCustomStyle";

const AccountView = ({ summaryData }) => {
  const isLoading = useSelector(state => state.generalReducer.isLoading);
  const domains = useSelector(state => state.domainReducer.selectBotzaDomains);

  const { defaultColumns } = Columns();
  const columns = defaultColumns;
  const [summaryDomainData, setSummaryDomainData] = useState([]);
  const onChangePage = useCallback(page => {});
  const onChangeRowsPerPage = useCallback((rowsPerPage, currentPage) => {});

  useEffect(() => {
    const sdd = [];
    summaryData.forEach(sd => {
      let currDomain = domains.find(t => t.label == sd.domain);
      if (currDomain) {
        (sd.bzActive = currDomain.bzActive),
          (sd.isMonitoring = currDomain.isMonitoring),
          (sd.bzPluginLatestVersion = currDomain.bzPluginLatestVersion),
          (sd.searchId = currDomain.searchId);
        sdd.push(sd);
      }
    });
    setSummaryDomainData(sdd);
  }, [summaryData]);

  return (
    <div>
      <DataTable
        columns={columns}
        data={summaryDomainData}
        pagination={true}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        loading={isLoading}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default AccountView;
