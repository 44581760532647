import { generalAction } from './types';

export const generalActionCreator = {
    setIsLoading: (isLoading) => ({
        type: generalAction.SET_IS_LOADING,
        payload: isLoading
    }),
    setUpdatedTime: (time) => ({
        type: generalAction.SET_UPDATED_TIME,
        payload: time
    }),
    addMinute: () => ({
        type: generalAction.ADD_A_MINUTE
    }),
    setError: (error) => ({
        type: generalAction.SET_ERROR,
        payload: error
    }),
    setSidebar: (isOpen) => ({
        type: generalAction.SET_SIDEBAR,
        payload: isOpen
    }),
    setConfig: (config) => ({
        type: generalAction.SET_CONFIG,
        payload: config
      })
}