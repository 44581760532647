import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Info }        from 'react-feather';
import ReactTooltip    from 'react-tooltip';
import Heatmap         from "../../../Charts/Heatmap";
import styles          from "./map.module.scss";
import { useTranslation } from 'react-i18next';
import { getCountry } from '../../../../services/countriesService';

const GB = 'GB';
const UK = 'UK';

const BlocksHeatmap = () => {
    const [chartData, setChartData] = useState(null);
    const blocksByCountry = useSelector((state) => state.summaryReducer.summary.blocksByCountry);
    const { t } = useTranslation(["dashboard", "common"])
    const createDatasets = () => {
        const dataSet = [
            [t("country", {ns: "common"}), t("numberOfBlocks", {ns: "common"})]
        ]

        blocksByCountry.map(item => {
            const country = item.country === UK ? GB : item.country;
            dataSet.push([{ v: country, f: getCountry(country) }, item.totalBlocked])
        })

        setChartData(dataSet);
    }

    useEffect(createDatasets, [blocksByCountry])

    return (
        <div>
            <ReactTooltip effect='solid' multiline type='light' />
            <div className={ styles.map }>
                <div>
                    <h4 className='fw-600'>{t('blocksHeatmap.title')}</h4>
                    <Info data-tip={t('blocksHeatmap.tooltip')} />
                </div>
                <div>
                    <Heatmap data={ chartData } />
                </div>
            </div>
        </div>
    )
}

export default BlocksHeatmap;