import React, { useCallback, forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../globals/Select'
import { useTranslation } from 'react-i18next'
import { filtersAction } from '../../../store/modules/filters/types'
import { filtersActionCreator } from '../../../store/modules/filters/action-creators'
import { domainsActionCreator } from '../../../store/modules/domains/action-creators'

const Domain = forwardRef((_, ref) => {
  const dispatch = useDispatch()
  const domains = useSelector(state => state.domainReducer.selectBotzaDomains)
  const isLoading = useSelector(state => state.generalReducer.isLoading)
  const selectedDomain = useSelector(state => state.domainReducer.selectedDomain)
  const [domain, setStateDomain] = useState(selectedDomain)
  const domainFilterFromStore = useSelector(state => state.filtersReducer.filters.domain)
  const { t } = useTranslation("dashboardNavbar")
  const setDomain = useCallback(domain => {
    setStateDomain(domain)
    dispatch(domainsActionCreator.setSelectedDomain(domain))
    dispatch(filtersActionCreator.setFilter(domain.value, filtersAction.SET_DOMAIN_FILTER))
  })

  const refresh = () => {
    dispatch(domainsActionCreator.setSelectedDomain(domains[0]))
    setStateDomain(domains[0])
  }

  useImperativeHandle(ref, () => ({refresh}))

  const setLocalFilters = () => {
    const localDomain = domains?.find(item => item.value === domainFilterFromStore)
    if (localDomain) {
      setStateDomain(localDomain)
      dispatch(domainsActionCreator.setSelectedDomain(localDomain))
    }
  }

  useEffect(setLocalFilters, [domainFilterFromStore])

  return (    
    <div>
      <label>{t("domain")}</label>
      <Select options={domains} value={domain} changeHandler={setDomain} />
    </div>
  )
})

export default Domain