import { filtersAction } from "./types";
import { today, weekAgo } from "../../../utils/date";

const filters = {
  limit: 300,
  offset: 0,
  "from-date": weekAgo(),
  "to-date": today(),
  last3months: false,
  group: "",
  countries: [],
  domain: "",
  search: ""
};

const initialState = {
  filters,
  defaults: { ...filters }
};

const filterToMerge = (updatedFilter, resetOffset = true) => {
  if(resetOffset)
    updatedFilter.offset = 0
  
  return updatedFilter
}

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case filtersAction.SET_GROUP_FILTER:
      return { ...state, filters: filterToMerge({ ...state.filters, group: action.payload }) }
    case filtersAction.SET_COUNTRY_FILTER:
      return { ...state, filters: filterToMerge({ ...state.filters, countries: action.payload }) }
    case filtersAction.SET_DOMAIN_FILTER:
      return { ...state, filters: filterToMerge({ ...state.filters, domain: action.payload }) }
    case filtersAction.SET_PERIOD_FILTER:
      return { ...state, filters: filterToMerge({ ...state.filters, "from-date": action.payload }) }
    case filtersAction.SET_PERIOD_FILTER_TO_DATE:
      return { ...state, filters: filterToMerge({ ...state.filters, "to-date": action.payload }) }
    case filtersAction.SET_LAST_3_MONTHS:
      return { ...state, filters: filterToMerge({ ...state.filters, last3months: action.payload }) }
    case filtersAction.SET_SEARCH_FILTER:
      return { ...state, filters: filterToMerge({ ...state.filters, search: action.payload }) }
    case filtersAction.INCREMENT_OFFSET:
      return { ...state, filters: filterToMerge({ ...state.filters, offset: state.filters.offset + 1 }, false) }
    case filtersAction.SET_OFFSET:
      return { ...state, filters: filterToMerge({ ...state.filters, offset: action.payload }, false) }
    case filtersAction.RESET:
      return { ...state, filters: state.defaults };
    default:
      return state;
  }
}
