export const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: "20px",
      fontWeight: "bold"
    }
  },
  headRow: {
    style: {
      backgroundColor: "#f7f0ed"
    }
  },
  rows: {
    style: {
      backgroundColor: "#FDFBFA"
    }
  },
  pagination: {
    style: {
      backgroundColor: "#f7f0ed"
    }
  }
};
