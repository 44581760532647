import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useLocation } from "react-router-dom"
import { PlusCircle, ChevronsLeft } from "react-feather"
import { generalActionCreator } from "../../../store/modules/general/action-creators"
import OnboardingModal from "../../Onboarding"
import ModalService from "../../../services/ModalService"
import logo from "../../../assets/images/primary-logo.svg"
import styles from "./sidebar.module.scss"
import { routes } from "./consts"
import { useTranslation } from "react-i18next" 
import { track, TRACK_EVENTS } from "../../../services/tracking"

const Sidebar = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const sidebarIsOpen = useSelector(state => state.generalReducer.sidebarIsOpen)
  const { t } = useTranslation("sideBar")
  const addDomain = () => {
    track(TRACK_EVENTS.BZClickedAddDomain)
    ModalService.open(OnboardingModal, { closeOption: true })
  }

  const closeSideBar = () => dispatch(generalActionCreator.setSidebar(false))

  useEffect(() => (document.getElementById("app_sidebar").style.width = sidebarIsOpen ? "238px" : "0"), [sidebarIsOpen])

  return (
    <div id="app_sidebar" className={styles.sidebar}>
      <img src={logo} alt="clickcease-logo" />
      <div className={styles.links}>
        {routes.map(item => (
          <NavLink key={item.label} className={location.pathname === item.location ? styles.activeLink : ""} exact to={item.location}>
            {item.icon}
            <span>{t(item.label)}</span>
          </NavLink>
        ))}
        {
          <div>
            <button className={styles.addDomainBtn} onClick={addDomain}>
              <PlusCircle size={14}/>
              {t('addDomain')}
            </button>
          </div>
        }
      </div>
      <div className={styles.sidebar_toogle}>
        <ChevronsLeft onClick={closeSideBar} size={24} />
      </div>
    </div>
  )
}

export default Sidebar
