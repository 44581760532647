import { modalActions } from "../store/modules/modal/types";
import { modalActionCreator } from "../store/modules/modal/action-creators";
import { store } from "../store/store";

const pop = () => store.dispatch(modalActionCreator[modalActions.POP]());

const ModalService = {
  open: (component, props = {}) => store.dispatch(modalActionCreator[modalActions.OPEN]({ component, props, close: pop })),
  close: () => store.dispatch(modalActionCreator[modalActions.CLOSE]()),
  push: (component, props = {}) => store.dispatch(modalActionCreator[modalActions.PUSH]({ component, props, close: pop })),
  pop
};

export default ModalService;
