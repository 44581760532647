import VerticalChartBar from "../../Charts/VerticalBar";
import styles from "./trafficBreakdown.module.scss"
import { useTranslation } from "react-i18next";
import Section from "../../common/Cards/Section"

const css = {
  body: styles.blocked_traffic_breakdown
}

const TrafficBreakdown = ({ chartData, chartDataOptions, title, header, tooltip, datedTrafficData = [] }) => { 
  const { t } = useTranslation("dashboard", {keyPrefix: "insights"});
  return (
    <Section
    title={t("title")}
    css={css}
      header={
        <section className={styles.header}>
            <div>{title}</div>
          <section className={styles.header_details}>{header}</section>
        </section>
      }
      body={
        <div className={styles.body}>
        {chartData && <VerticalChartBar data={chartData} options={chartDataOptions} />}
      </div>
      }
    />
  )
}

export default TrafficBreakdown