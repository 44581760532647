import { SplitFactory } from "@splitsoftware/splitio-react";
import { useSelector } from "react-redux";

const SplitWrapper = (props) => {
  const config = useSelector(state => state.generalReducer.splitConfig);

  return(
    config? 
  <SplitFactory factory={config} updateOnSdkUpdate={true}>
    {props.children}
    </SplitFactory>:
     props.children)
}

export default SplitWrapper

