import { generalActionCreator } from "../store/modules/general/action-creators";
import { useLocalStorageUser } from "./User";
import { ROUTES } from "../constants";

const { clearLocalStorageUser } = useLocalStorageUser();

export const requestHandler = (dispatch, requestFunction, filters, updateTimer = true, loader = true) => {
  if(loader) dispatch(generalActionCreator.setIsLoading(true));
  // it will redirect to login except for on admin login 
  if(!useLocalStorageUser().getToken() && !window.location.href.includes('admin-login')){
    window.location.href = ROUTES.login
  }
  return requestFunction(filters)
    .then(response => {
      if (updateTimer) dispatch(generalActionCreator.setUpdatedTime(0));
      const data = response?.data === "" ? true : response?.data;
      return { data };
    })
    .catch(error => {
      const { data, status } = error?.response;
      if (status === 401) clearLocalStorageUser();
      return { error };
    })
    .finally(() => dispatch(generalActionCreator.setIsLoading(false)));
};
