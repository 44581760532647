import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend'
import { DEFAULT_LANGUAGE } from '../constants';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    ns: ['dashboardNavbar', 'sideBar', 'dashboard', 'common'],
    defaultNS: 'dashboard',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backends: [
      resourcesToBackend((language, namespace, callback) => {
        import(`../../public/locales/${language}/${namespace}.json`)
            .then(({ default: resources }) => { // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
                callback(null, resources)
            })
            .catch((error) => {
                callback(error, null)
            })
      })
    ],
    parseMissingKeyHandler: (key) => {
      return `[[MISSING KEY: ${key}]]`;
    }
  })

export default i18n