import { summaryAction }  from './types';
import { summaryApi }     from '../../../services/summaryApi';
import { requestHandler } from '../../../utils/requestHandler';

export const summaryActionCreator = {
    setSummary: (summary) => ({
        type: summaryAction.SET_SUMMARY,
        payload: summary
    }),
    updateSummary: (filters, loader = true) => async (dispatch) => {
        const { data } = await requestHandler(dispatch, summaryApi.getSummary, filters, true, loader);
        if(data) dispatch(summaryActionCreator.setSummary(data));
    }
}