import { useAxiosInstance } from './axiosInstance';
const instance = useAxiosInstance().instance;

export const blocksReportApi = {
    getBlocks(filters) {
        return instance().get(`/blocked`, { params: filters });
    },
    getCSV(filters) {
        return instance().post(`/blocked/download`, null, { params: filters });
    }
}