import { domainsAction } from "./types";
import { domainsApi } from "../../../services/domainService";
import { requestHandler } from "../../../utils/requestHandler";
import { tagsApi } from "../../../services/tagService";
import { useLocalStorageUser } from "../../../utils/User";

const { getId } = useLocalStorageUser();

export const domainsActionCreator = {
  setBotzaDomains: domains => ({
    type: domainsAction.SET_BOTZA_DOMAINS,
    payload: domains
  }),
  setCheckedDomains: checkedDomains => dispatch => {
    dispatch({
      type: domainsAction.SET_CHECKED_DOMAIN,
      payload: checkedDomains
    });
  },
  setNoDomains: value => ({
    type: domainsAction.SET_NO_DOMAINS,
    payload: value
  }),
  setDomainsLimits: value => ({
    type: domainsAction.SET_DOMAINS_LIMITS,
    payload: value
  }),
  setSortDomains: asc => dispatch => {
    dispatch({
      type: domainsAction.SORT_DOMAINS,
      payload: asc
    });
  },
  setBotzaDomainFilter: searchInput => dispatch => {
    dispatch({
      type: domainsAction.FILTER_BOTZA_DOMAIN,
      payload: searchInput
    });
  },
  setSelectedDomain: domain => dispatch => {
    dispatch({
      type: domainsAction.SET_SELECTED_DOMAIN,
      payload: domain.value
    });
  },
  addSelectedDomain: domain => dispatch => {
    dispatch({
      type: domainsAction.ADD_SELECTED_DOMAIN,
      payload: domain
    });
  },
  filterDomain: filter => dispatch => {
    dispatch({
      type: domainsAction.FILTER_DOMAIN,
      payload: filter
    });
  },
  getDomainsLimits: () => async dispatch => {
    const { data } = await requestHandler(dispatch, domainsApi.getDomainsLimits, null);
    if (data) dispatch(domainsActionCreator.setDomainsLimits(data?.NumberOfAvailableDomains));
  },
  getBotzaDomains: () => async dispatch => {
    const { data, error } = await requestHandler(dispatch, domainsApi.getDomains, null);
    let botzappDomains =
      data?.Domains?.map(domain => ({
        domain: domain.Address,
        isPaidMarketingEnabled: domain.IsPaidMarketingEnabled,
        isBotZappingEnabled: domain.IsBotZappingEnabled,
        bzActive: domain.BotZappingActive,
        bzPluginLatestVersion: domain.BZPluginLatestVersion
      })) || [];

    if (data && botzappDomains.find(item => item.isBotZappingEnabled)) {
      const { data: botzaTags } = await requestHandler(dispatch, tagsApi.getTags, null);
      botzappDomains = botzappDomains.map(botzaDomain => {
        const currentTag = botzaTags?.find(tag => tag.value.toLowerCase() === botzaDomain.domain.toLowerCase());
        return {
          ...botzaDomain,
          searchId: currentTag?.searchId,
          apiKey: currentTag?.apiKey,
          hash: currentTag?.hash,
          isMonitoring: currentTag?.monitoringMode?.isMonitoring,
          monitoringIntervals: currentTag?.monitoringMode?.dates
        };
      });
    }
    await dispatch(domainsActionCreator.setBotzaDomains(botzappDomains));
    return { data, error };
  },
  addDomain: DomainAddress => async dispatch => {
    const { data, error } = await requestHandler(dispatch, domainsApi.addDomain, { Id: getId(), DomainAddress });
    if (data) {
      const { data: response, error: tagError } = await requestHandler(dispatch, tagsApi.addTags, [{ active: true, value: DomainAddress }]);
      if (tagError) return tagError;
      dispatch({
        type: domainsAction.ADD_DOMAIN,
        payload: DomainAddress
      });
      return { data: response };
    }
    return { error };
  },
  updateDomains: domainsChecked => async dispatch => {
    const DomainsToUpdate = domainsChecked.map(value => ({
      Address: value,
      IsBotZappingEnabled: true,
      IsPaidMarketingEnabled: true
    }));
    const { data, error } = await requestHandler(dispatch, domainsApi.updateDomains, { Id: getId(), DomainsToUpdate });
    if (data) {
      const tagData = domainsChecked.map(value => ({
        active: true,
        value: value
      }));
      const { data: response, error: tagError } = await requestHandler(dispatch, tagsApi.addTags, tagData);
      if (tagError) return { error: tagError };
      dispatch({
        type: domainsAction.UPDATE_DOMAINS,
        payload: domainsChecked
      });
      return { data: response };
    }
    return { error };
  },
  deleteTags: tags => async dispatch => {
    const tagsToDelete = tags.map(tag => ({ searchId: tag.searchId }));
    const { data } = await requestHandler(dispatch, tagsApi.deleteTags, tagsToDelete);
    if (data) {
      const DomainsToUpdate = tags.map(tag => ({
        Address: tag.domain,
        IsBotZappingEnabled: false,
        IsPaidMarketingEnabled: tag.isPaidMarketingEnabled
      }));
      await requestHandler(dispatch, domainsApi.updateDomains, { Id: getId(), DomainsToUpdate });
    }
    dispatch(domainsActionCreator.getBotzaDomains());
  },
  updatePaidMarketing: DomainsToUpdate => async dispatch => {
    DomainsToUpdate = [
      {
        Address: DomainsToUpdate.domain,
        IsBotZappingEnabled: DomainsToUpdate.isBotZappingEnabled,
        IsPaidMarketingEnabled: DomainsToUpdate.isPaidMarketingEnabled
      }
    ];
    await requestHandler(dispatch, domainsApi.updateDomains, { Id: getId(), DomainsToUpdate });
    dispatch(domainsActionCreator.getBotzaDomains());
  },
  updateMonitoring: DomainsToUpdate => async dispatch => {
    await requestHandler(dispatch, tagsApi.updateTagMonitoring, { searchId: DomainsToUpdate.searchId, isMonitoring: DomainsToUpdate.isMonitoring });
    dispatch(domainsActionCreator.getBotzaDomains());
  }
};
