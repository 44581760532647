import styles from "./top.module.scss"
import ProgressBar from "../../../globals/ProgressBar"
import Flag from "react-world-flags"
import { numberToString } from "../../../../utils/stringify"
import i18n from "../../../../i18n"
import { getCountry } from "../../../../services/countriesService"

const Table = ({ data }) => {
  const t = i18n.getFixedT(null, "common")
  return (
    <div className={styles.table}>
      <div>
        <span className='fw-600'>{t("country")}</span>
        <span className='fw-600'>{t("invalidBlocks")}</span>
        <span className='fw-600'>{t("allBlockspercentage")}</span>
      </div>
      <div>
        {data &&
          data.map((item, index) => (
            <div className={styles.table_row} key={index}>
              <span>
                <Flag code={item.country} height="16" width="25"/>
                <span>{getCountry(item.country)}</span>                
              </span>
              <span className={styles.invalid_blocks_column}>
                <ProgressBar className={styles.progressbar} value={item.percent + 25} />
                <span>{numberToString(item.blocked)}</span>
              </span>
              <span className={styles.blocks_percentage_column}>{Math.round(item.percent)}%</span>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Table