import { Oval } from "react-loader-spinner";
import styles from "./loader.module.scss";
import { memo } from "react";

const Loader = memo(({ fullScreen }) => {
  const className = styles.loader + (fullScreen ? ` ${styles["full-screen"]}` : "");
  return (
    <div className={className}>
      <Oval secondaryColor="var(--base-color)" color="var(--base-color)" height={100} width={100} />
    </div>
  );
});

export default Loader;
