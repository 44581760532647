export const COUNTRY = "country"
export const URL = "url"
export const ACTION = 'Action Taken:'
export const DOMAIN = "domain"
export const IP = "ip"
export const THREAT_GROUP = "threatGroup"
export const THREAT_TYPE = "threatType"
export const CSS_PREFIX = "blocked_ip_"
export const TRANSLATION_PREFIX = "table.header"
export const LAST_SEEN = "lastSeen"
export const BLOCKED_IPS = "blockedIps"
export const INTERSECTION_OPTIONS = {
  root: null,
  rootMargin: "0px",
  thershold: 1.0
}
export const MONITORING_ACTION = 'Monitored Only'
export const BLOCKED_ACTION = 'Blocked'