import React from 'react';

const BlockedIPRow = ({ css, title, value }) => {
  return (
    <>
      <span className={css?.title} >{title}</span>
      <span className={css?.value}>{value}</span>
    </>
  );
};

export default BlockedIPRow;