import React, { memo } from 'react'
import DashboardNavbar from '../../../components/common/DashboardNavbar'
import BlocksTable from '../../../components/BlocksReport/Table'

const DesktopView = memo(({ className }) => {
  return (
    <div className={ className }>
      <DashboardNavbar/>
      <BlocksTable/>
    </div>
  )
})

export default DesktopView