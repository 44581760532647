import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tagsActionCreator } from "../../../store/modules/tags/action-creators";
import { PLUGIN_URL } from "../../../constants/index";
import ClipboardCopy from "../../globals/ClipboardCopy";
import "./style.scoped.scss";
import { useTranslation } from "react-i18next";

const ProtectionSetupBanner = () => {
  const dispatch = useDispatch();
  const secretKey = useSelector(state => state.tagsReducer.secretKey);
  const { t: protectionSettingsT } = useTranslation("protectionSettings", { keyPrefix: "protectionSetupBanner" })
  const { t: commonT } = useTranslation("common")
  useEffect(async () => {
    const secretKeyResponse = await dispatch(tagsActionCreator.getSecretKey());
  }, []);

  return (
    <div className="banner">
      <p className="bold title fs-18">{protectionSettingsT("title", { ns: "protectionSettings" })}</p>
      <span className="pl-28 fs-14">{protectionSettingsT("installationInstructions")}</span>
      <div className="pl-28 mt-5 fs-14">
        <div>
          <p className="round-shadow inline-block">1</p>
          <a href={PLUGIN_URL} target="_blank" className="text-color-base cursor-pointer text-decoration-none">
            {commonT("download")}
          </a>
          <label> {protectionSettingsT("downloadWordpressPlugin")}</label>
        </div>
        <ClipboardCopy number={2} copyText={secretKey} title={protectionSettingsT("enterSecretkey")} />
        <div>
          <p className="round-shadow inline-block">3</p>
          <label>{protectionSettingsT("enterYourDomainKeys")}</label>
        </div>
      </div>
    </div>
  );
};

export default ProtectionSetupBanner;