import { EAuthAction } from "./types";
import { useLocalStorageUser } from "../../../utils/User";
import { loginAPI } from "../../../services/loginAPI";
import { requestHandler } from "../../../utils/requestHandler";
import i18n from "../../../i18n";
import { init as initTracker } from "../../../services/tracking";
import { createSDK } from "../../../services/splitIO/helpers";
import { generalActionCreator } from "../general/action-creators";

export const authActionCreator = {
  setUser: user => ({
    type: EAuthAction.SET_USER,
    payload: user
  }),

  login: () => async dispatch => {
    const { getLocalStorageUser } = useLocalStorageUser();
    const user = getLocalStorageUser();
    if (user.language && user.language != i18n.language) i18n.changeLanguage(user.language);
    if(user.clientId){
      if (window.Appcues) window.Appcues.identify(user.clientId)
      if(!user.showLogout) initTracker(user.clientId)
      const splitConfig = createSDK(user.clientId)
      dispatch(generalActionCreator.setConfig(splitConfig));
    }
    dispatch(authActionCreator.setUser(user));
  },
  getClientIds: user => dispatch => requestHandler(dispatch, loginAPI.getClientIds, user),
  impersonate: user => dispatch => requestHandler(dispatch, loginAPI.impersonate, user)
};
