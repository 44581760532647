import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dateGraphFormat, getFormattedMonth } from "../../../utils/date";
import { DATASETS } from "./consts";
import { numberToString, stringTo2PointDecimal } from "../../../utils/stringify";
import styles from "./summary.module.scss";
import { useTranslation } from "react-i18next";
import { SummaryCard } from "../../common/Cards/Summary/summary";
import TrafficBreakdown from "../TrafficBreakdown";
import AccountView from "./AccountView";
import { EVENT_ON, FEATURE_DOMAIN_STATUS, UNDEFINED_EVENT_VALUE } from "../../../services/splitIO/helpers";
import { SplitContext } from "@splitsoftware/splitio-react";

const Summary = () => {
  const { client } = useContext(SplitContext);
  const domainStatusTreatment = client?.getTreatment(FEATURE_DOMAIN_STATUS);
  const { t: summaryT } = useTranslation("dashboard", { keyPrefix: "summary" });
  const { t: blockedTrafficBreakdownT } = useTranslation("dashboard", { keyPrefix: "blockedTrafficBreakdown" });
  const [chartData, setChartData] = useState(null);
  const [options, setOptions] = useState(null);
  const summaryData = useSelector(state => state.summaryReducer.summary.summary.summary);
  const datedSummaries = useSelector(state => state.summaryReducer.summary.datedSummaries);
  const monthlySummaries = useSelector(state => state.summaryReducer.summary.monthlySummaries);
  const filter = useSelector(state => state.filtersReducer.filters);
  const summaryObj = useSelector(state => state.summaryReducer.summary.summary);
  const summary = filter.domain && summaryObj.summary?.length ? summaryObj.summary[0] : summaryObj.total;

  const showAccountView =
    domainStatusTreatment !== UNDEFINED_EVENT_VALUE && domainStatusTreatment === EVENT_ON && !filter.domain && summaryData?.length && !filter.domain && summaryData?.length;

  const translatedblockedPageloads = blockedTrafficBreakdownT(`${DATASETS.BLOCKED_PAGE_LOADS}.title`);
  const translatedblockedSiteInteractions = blockedTrafficBreakdownT(`${DATASETS.BLOCKED_SITE_INTERACTIONS}.title`);

  const createDatasets = () => {
    const data = filter.last3months ? monthlySummaries : datedSummaries;
    const initialData = { labels: [], series: { [DATASETS.BLOCKED_PAGE_LOADS]: [], [DATASETS.BLOCKED_SITE_INTERACTIONS]: [] } };
    const reducedData = data.reduce((accumolator, currentValue) => {
      const label = filter.last3months ? getFormattedMonth(currentValue.month) : dateGraphFormat(new Date(currentValue.date));
      accumolator.labels.unshift(label);
      accumolator.series[DATASETS.BLOCKED_PAGE_LOADS].unshift(currentValue[DATASETS.BLOCKED_PAGE_LOADS]);
      accumolator.series[DATASETS.BLOCKED_SITE_INTERACTIONS].unshift(currentValue[DATASETS.BLOCKED_SITE_INTERACTIONS]);
      return accumolator;
    }, initialData);

    const dataSet = {
      labels: reducedData.labels,
      series: [
        {
          name: translatedblockedPageloads,
          data: reducedData.series[DATASETS.BLOCKED_PAGE_LOADS]
        },
        {
          name: translatedblockedSiteInteractions,
          data: reducedData.series[DATASETS.BLOCKED_SITE_INTERACTIONS]
        }
      ]
    };

    if (JSON.stringify(dataSet) !== JSON.stringify(chartData)) {
      const max_yaxis = dataSet?.series ? Math.max(...dataSet.series.map(x => x.data).flat()) : 0;
      setChartData(dataSet);
      setOptions({
        // fill: {
        //   colors: undefined,
        //   opacity: 0.1
        // },
        legend: { show: false },
        colors: ["#FE0072", "#AC195B"],
        chart: { toolbar: { show: false }, stacked: true },
        dataLabels: { enabled: false },
        xaxis: {
          categories: dataSet.labels
        },
        yaxis: {
          tickAmount: max_yaxis > 1 ? 0 : 1,
          max: max_yaxis < 1 ? 1 : undefined,
          labels: {
            formatter: val => Math.round(val)
          }
        },
        tooltip: {
          y: {
            formatter: val => {
              const decimal = (val - Math.floor(val)) * 1000000000;
              return Math.round(val + decimal);
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    createDatasets();
  }, [datedSummaries]);

  const { protectedValidVisits, badBotsAndInvalidRequests, knownCrawlers } = summaryT("sections", { returnObjects: true });
  return (
    <div className={styles.summary}>
      {showAccountView && <AccountView summaryData={summaryData} />}
      <h1>{summaryT("title")}</h1>
      <div className={`flex ${styles.content}`}>
        <SummaryCard
          mainTitle={protectedValidVisits.title}
          secondaryContent={numberToString(summary.protectedValidVisits)}
          mainContent={protectedValidVisits.description}
          secondaryTitle={summary.protectedValidVisitsPercentage && `${stringTo2PointDecimal(summary.protectedValidVisitsPercentage)}%`}
          expanded={protectedValidVisits.extendedDescription}
        />
        <SummaryCard
          mainTitle={badBotsAndInvalidRequests.title}
          secondaryContent={numberToString(summary.totalBlocked)}
          mainContent={badBotsAndInvalidRequests.description}
          secondaryTitle={summary.invalidRate && `${stringTo2PointDecimal(summary.invalidRate)}%`}
          expanded={badBotsAndInvalidRequests.extendedDescription}
        />
        <SummaryCard
          mainTitle={knownCrawlers.title}
          secondaryContent={numberToString(summary.whiteListed)}
          mainContent={knownCrawlers.description}
          secondaryTitle={summary.whiteListedPercentage && `${stringTo2PointDecimal(summary.whiteListedPercentage)}%`}
          expanded={knownCrawlers.extendedDescription}
        />
      </div>
      <TrafficBreakdown
        title={blockedTrafficBreakdownT("blockedTrafficBreakdown")}
        chartData={chartData}
        chartDataOptions={options}
        header={
          <section>
            <div className="bullet backgroung-pink">{translatedblockedPageloads}</div>
            <div className="bullet background-dark-pink">{translatedblockedSiteInteractions}</div>
          </section>
        }
        datedTrafficData={filter.last3months ? monthlySummaries : datedSummaries}
      />
    </div>
  );
};

export default Summary;
