import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import DomainStatus from "../../../common/Components/DomainStatus";

export const Columns = () => {
  const commonT = i18n.getFixedT(null, "common");
  const { t: summaryT } = useTranslation("dashboard", { keyPrefix: "summary" });
  const { t } = useTranslation("dashboard");

  const { protectedValidVisits, badBotsAndInvalidRequests, knownCrawlers } = summaryT("sections", { returnObjects: true });

  const defaultColumns = [
    {
      name: commonT("domain"),
      selector: row => row.domain,
      width: "200px",
      sortable: true
    },
    {
      name: protectedValidVisits.title,
      selector: row => row.protectedValidVisits,
      width: "200px",
      sortable: true
    },
    {
      name: t("badBots"),
      selector: row => row.totalBlocked,
      width: "150px",
      sortable: true
    },
    {
      name: knownCrawlers.title,
      selector: row => row.whiteListed,
      width: "250px",
      sortable: true
    },
    {
      name: commonT("status"),
      selector: row => <DomainStatus selectedDomain={row} />,
      width: "370px"
    }
  ];

  return {
    defaultColumns
  };
};
