import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStopwatch } from 'react-timer-hook';
import { summaryActionCreator } from "../../store/modules/summary/action-creators";
import { checkDataInterval } from "./consts";
import { mockData } from "./mock";
import DashboardNavbar from "../../components/common/DashboardNavbar";
import Summary from "../../components/Dashboard/Summary/index";
import Insights from "../../components/Dashboard/Insights/index";
import Countries from "../../components/Dashboard/Countries/index";
import LoaderWrapper from "../../components/Loader/LoaderWrapper";
import HoldTight from "../../components/Dashboard/HoldTight";
import styles from "./dashboard.module.scss";

const Dashboard = ({ fakeData }) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filtersReducer.filters);
  const noData = useSelector(state => state.summaryReducer.noData);

  const fetch = () => {
    if(!fakeData) dispatch(summaryActionCreator.updateSummary(filters));
    else dispatch(summaryActionCreator.setSummary(mockData));
  }

  useEffect(() => {    
    const interval = setInterval(() => { 
      if(noData) dispatch(summaryActionCreator.updateSummary(filters)) 
    }, 5 * 1000 )
    return () => clearInterval(interval)
  }, [])

  useEffect(fetch, [filters, fakeData]);

  return (
    <div className={styles.dashboard}>
      <LoaderWrapper>
        <DashboardNavbar onRefresh={fetch}/>
        {noData && <HoldTight />}
        <Summary />
        <Insights />
        <Countries />
      </LoaderWrapper>
    </div>
  );
};

export default Dashboard;
