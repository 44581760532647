import React, { useEffect } from "react";
import { RefreshCcw } from "react-feather";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useStopwatch } from "react-timer-hook";
import { filtersActionCreator } from "../../../store/modules/filters/action-creators";
import { filtersAction } from "../../../store/modules/filters/types";
import styles from "./navbar.module.scss";
import { useTranslation } from "react-i18next";
import Domain from "../Filters/Domain";
import Dates from "../Filters/Dates";
import DomainStatus from "../Components/DomainStatus";
import { useCallback } from "react";
import { blocksActionCreator } from "../../../store/modules/blocks/action-creators";
import { SplitContext } from "@splitsoftware/splitio-react";
import { EVENT_ON, FEATURE_DOMAIN_STATUS, UNDEFINED_EVENT_VALUE } from "../../../services/splitIO/helpers";
import { useContext } from "react";

const DashboardNavbar = () => {
  const { t } = useTranslation("dashboardNavbar");
  const dispatch = useDispatch();
  const { minutes, start, reset } = useStopwatch({ autoStart: true });
  const isLoading = useSelector(state => state.generalReducer.isLoading);
  const { filters, defaults } = useSelector(state => state.filtersReducer);
  const selectedDomain = useSelector(state => state.domainReducer.selectedDomain);
  const { client } = useContext(SplitContext);
  const domainStatusTreatment = client?.getTreatment(FEATURE_DOMAIN_STATUS);
  const showDomainStatus = domainStatusTreatment !== UNDEFINED_EVENT_VALUE && domainStatusTreatment === EVENT_ON;
  const clearFilters = useCallback(async () => {
    await dispatch(filtersActionCreator.setFilter(null, filtersAction.RESET));
    if (shallowEqual(defaults, filters)) await dispatch(blocksActionCreator.updateBlockedIps());
  }, [filters, defaults]);

  useEffect(start, []);
  useEffect(reset, [isLoading]);

  return (
    <div className={styles.dashboard_navbar}>
      {
        <section className={styles.filters}>
          <Domain />
          {showDomainStatus && selectedDomain?.value && <DomainStatus selectedDomain={selectedDomain} />}
        </section>
      }
      <section className={styles.filters}>
        <Dates />
        <div className={styles.refresher}>
          <label>
            {t("updated", { ns: "common" })} {!minutes ? t("justNow") : t("xMinutesAgo", { minutes })}
          </label>
          <button style={{ width: "105px", height: "unset" }} className={showDomainStatus ? `${styles.refresher_domain_status}` : ""} onClick={clearFilters}>
            <RefreshCcw size={16} className={styles.refresher_icon} />
            {isLoading ? t("loading", { ns: "common" }) : t("refresh", { ns: "common" })}
          </button>
        </div>
      </section>
    </div>
  );
};

export default DashboardNavbar;
