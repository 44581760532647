import { RotatingLines } from "react-loader-spinner";
import "./style.scoped.scss";
import { useTranslation } from "react-i18next";

const HoldTight = ({showTitle = true, className}) => {
  const { t } = useTranslation("common")
  return (
    <div>
      <div className={`hold-tight ${className || ""}`}>        
        <RotatingLines strokeColor="#FC6723" ariaLabel="loading-indicator" width="40" />        
        {showTitle && <span className="spinner-text">{t("holdTight")}</span>}
      </div>
    </div>
  );
};

export default HoldTight;