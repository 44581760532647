import CryptoJS from "crypto-js";
import { SECRET } from "../constants";

/**
 * The default storage is localStorage,
 * to change, bind sessionStorage
 * ex: getStorageItem.bind(sessionStorage)
 * @param {string} key
 */
export function getStorageItem(key, encrypt = true) {
    const storage = this || localStorage;
    const item = storage.getItem(key);
    if (!item) return;

    const decrypted = encrypt ? CryptoJS.AES.decrypt(item, SECRET).toString(CryptoJS.enc.Utf8) : item;
    try {
        return JSON.parse(decrypted);
    } catch (e) {
        return decrypted;
    }
}

/**
 * The default storage is localStorage,
 * to change, bind sessionStorage
 * ex: setStorageItem.bind(sessionStorage)
 * @param {string} key
 * @param {string} value
 * @param {boolean} [encrypt=true] [default is true]
 */
export function setStorageItem(key, value, encrypt = true) {
    const storage = this || localStorage;
    const encrypted = encrypt ? CryptoJS.AES.encrypt(value, SECRET) : value;
    return storage.setItem(key, encrypted);
}