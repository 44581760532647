import React, { forwardRef, memo, useImperativeHandle, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from "../../globals/Select"
import ReactTooltip from 'react-tooltip'
import { components } from "react-select"
import { filtersActionCreator } from "../../../store/modules/filters/action-creators"
import { filtersAction } from "../../../store/modules/filters/types"
import { getCountries } from '../../../services/countriesService'
import { countryMultiValueLabel, countryOption, countryControl } from "../../common/ReactSelect"
import styles from "./filters.module.scss"
import { useMemo } from 'react'
import { COUNTRY } from "./Constants.js"

const { GB, UK, MAX_OPTIONS_TO_SHOW, DATA_FOR, SELECT_OPTION } = COUNTRY
const countrySelectCustomStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 4px',
    display: "flex",
    flexWrap: "noWrap",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    borderRadius: '20px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "unset"
  }),
}

const ValueContainerItem = (searchInput) => ({ children, getValue, ...props }) => {
  var length = getValue().length
  let search = children[children.length-1]
  let displayChips = React.Children.toArray(children).slice(0, MAX_OPTIONS_TO_SHOW)
  let shouldBadgeShow = length > MAX_OPTIONS_TO_SHOW
  let displayLength = length - MAX_OPTIONS_TO_SHOW

  return (
    <components.ValueContainer {...props} inputValue={searchInput}>
      {displayChips}
      <span className="root" style={{fontSize:"14px", marginLeft:"4px"}}>
        {shouldBadgeShow &&
          ` +${displayLength}`}
      </span>
      {search}
    </components.ValueContainer>
  )
}

const Country = forwardRef(({searchInput, isMulti = false}, ref) => {
  const { t } = useTranslation(["blocksReport", "common"])
  const allCountriesOption = { label: t("options.allCountries"), value: "", isSelected: true }
  const [countryFilter, setCountryFilter]  = useState([{ ...allCountriesOption }])
  const countries = getCountries()
  const countryOptions = [ allCountriesOption, ...Object.keys(countries).map(key => { return {value: key, label: countries[key]}})]
  const dispatch = useDispatch()

  const setCountries = (countries, {action, option}) => {
    let selectedCountries
    switch(action){
      case SELECT_OPTION:
        if(!option.value)
          selectedCountries = [{ ...allCountriesOption }]
        else {
          const otherSelectedCountries = countryFilter.filter(option=>option.value)
          selectedCountries = [...otherSelectedCountries, option]
        }
        break
      default:
        if(!countries.length)
          selectedCountries = [{ ...allCountriesOption }]
        else
          selectedCountries = countries
        break
    }
    setCountryFilter(selectedCountries)
    dispatch(filtersActionCreator.setFilter(selectedCountries.reduce((accumulator, current) => {
      if(current.value)
        accumulator.push(current.value === GB ? UK : current.value)
      return accumulator
    }, []), filtersAction.SET_COUNTRY_FILTER))
  }

  const clear = () => setCountryFilter([{ ...allCountriesOption }])

  useImperativeHandle(ref, () => ({clear}))

  const countrySelectComponents = useMemo( () => ({
    Control: countryControl(DATA_FOR),
    MultiValueLabel: countryMultiValueLabel(styles["selected-country"]),
    Option: countryOption(styles["country-select-option"]),
    ValueContainer: ValueContainerItem(searchInput)
  }), [searchInput])

  return (
    <div className='flex'>
      {
        countryFilter?.length > 1 &&
        (<ReactTooltip effect='solid' id='country-filter-value' type='light'>
              { countryFilter.map(country => country.value ? <Country key={country.value} country={country.value}/> : country.label) }
        </ReactTooltip>)
      }
      <span className="mr-8 fs-14">{t("country")}</span>
        <Select
          minWidth='210px'
          maxWidth='unset'
          changeHandler={ setCountries }
          value={ countryFilter }
          options={ countryOptions }
          isMulti
          components={countrySelectComponents}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          customStyles={countrySelectCustomStyles}
        />
    </div>
  )
})

export default Country