import { useSelector } from "react-redux";
import styles from "./loader.module.scss";
import Loader from "./index";

const LoaderWrapper = ({ children, className, fullScreen = false }) => {
  const isLoading = useSelector(state => state.generalReducer.isLoading);
  return (
    <div className={`${styles["loader-wrapper"]} ${className ? className : ""}`}>
      {isLoading && <Loader fullScreen={fullScreen} />}
      {children}
    </div>
  );
};

export default LoaderWrapper;
