import { useAxiosInstance } from "./axiosInstance";

const axiosInstance = useAxiosInstance();

export const loginAPI = {
  getClientIds({ imp, email, password }){
    const param  = {
      "ClientEmail": imp,
      "UserName": email,
      "Password": password
    }
    return axiosInstance.clickCeaseInstance().post("/BotZappingAuth/GetClientLoginId", param)
  },
  impersonate(payload) {
    return axiosInstance.instance().post("/authorize", payload);
  }
};