import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./styles/Modal.module.scss";
import { CSSTransition } from "react-transition-group";

const ModalRoot = () => {
  const modals = useSelector(state => state.modalReducer.modals)
  const modal = modals ? modals[modals.length - 1] : null

  return (
    <CSSTransition
      in={!!modals.length}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <section className={styles.modalRoot}>
        {modal && modal.component && <modal.component {...modal.props} close={modal.close} className="d-block" />}
      </section>
    </CSSTransition>
  )
};

export default ModalRoot;
