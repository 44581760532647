import styles from './progress.module.scss';

const ProgressBar = ({ value }) => {
    value = value > 100 ? 100: value;
    const fillerRelativePercentage = (100 / value) * 100;
    const fillerFlex = value > 0 ? value / 100 : 0;

    return (
        <div className={ styles.progressbar } role="progressbar" aria-valuemin={ 0 } aria-valuemax={ 100 } aria-valuenow={ value }>
            <div style={{ flex: fillerFlex }}>
                <div style={{ width: `${ fillerRelativePercentage }%` }} />
            </div>
        </div>
    );
};

export default ProgressBar;