import { blocksAction }         from './types';
import { blocksReportApi }      from '../../../services/blocksReportApi';
import { requestHandler }       from '../../../utils/requestHandler';

const FETCH_LIMIT = 300;
const MOBILE_FETCH_LIMIT = 50;

export const blocksActionCreator = {
    setBlockedIps: (ips) => ({
        type: blocksAction.SET_BLOCKS,
        payload: ips
    }),
    getCSV: () => async (dispatch, getState) => {
      const state = getState() 
      const filters = state.filtersReducer.filters
      const { data } = await requestHandler(dispatch, blocksReportApi.getCSV, filters, false);
      if(data) window.open(data.url,'_blank');
    },
    updateBlockedIps: () => async (dispatch, getState) => {
      const state = getState() 
      const isMobile = state.generalReducer.isMobileResolution
      const botzaDomains = state.domainReducer.botzaDomains
      const filters = state.filtersReducer.filters
      const updatedFilters = { ...filters, limit: isMobile ? MOBILE_FETCH_LIMIT : FETCH_LIMIT };
      const { data } = await requestHandler(dispatch, blocksReportApi.getBlocks, updatedFilters);
      if(data && data.blockedIps && (!filters.offset || data.blockedIps.length)) dispatch(blocksActionCreator.setBlockedIps({ ...data, offset: filters.offset, botzaDomains }));
    }
}