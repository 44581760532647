export const formatDate = (date = new Date()) => {
    const year = date.getFullYear();
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();

    if(month.length < 2) month = '0' + month;
    if(day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

// MM/YYYY FORMAT CHANGE FOR SAFARI DATE BUG
export const getFormattedMonth = (month) => {
    let monthArr = month.split('-');
    const date = new Date(monthArr[0], monthArr[1]);
    date.setMonth(date.getMonth()-1);
    return date.toLocaleString("default", { month: "long" });
  };


export const dateGraphFormat = (date = new Date()) => {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();

    if(month.length < 2) month = '0' + month;
    if(day.length < 2) day = '0' + day;

    return [day, month].join('.');
}

const getPastDate = (day = 0) => {
    const date = new Date();
    date.setDate(date.getDate() - day);
    return formatDate(date);
}

export const today = () => {
   return getPastDate();
}

export const weekAgo = () => {
    return getPastDate(6);
}

export const monthAgo = () => {
    return getPastDate(29);
}

export const yearAgo = () => {
    return getPastDate(365);
}