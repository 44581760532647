import React, {useEffect, useState} from "react";
import styles from "./charts.module.scss";
import Chart  from 'react-apexcharts';

const VerticalChartBar = ({ data, options }) => {
    const [showChart, setShowChart] = useState(false)
    useEffect(() => {
        setShowChart(false);
        setTimeout(() => {
            setShowChart(true)
        }, 10)
    }, [data, options])
    return (
        <div className={ styles.vertical_bar }>
            { showChart && <Chart options={ options } series={ data.series } type="bar" height={ 250 } /> }
        </div>
    )
}

export default VerticalChartBar;