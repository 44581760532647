import React, { useEffect } from "react";
import DataTable       from "react-data-table-component";
import styles          from "./data-table.module.scss";
import LoaderWrapper   from "../../Loader/LoaderWrapper";
import { useTranslation } from "react-i18next";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../../constants";

const Filters = () => null;
const Table = ({ 
  columns, 
  data, 
  children, 
  fixedHeader = true, 
  paginationTotalRows, 
  pagination = false, 
  onChangePage, 
  onChangeRowsPerPage, 
  paginationComponentOptions: options, 
  resetPaginationToggle = false,
  paginationRowsPerPageOptions = [DEFAULT_TABLE_PAGE_SIZE],
  paginationPerPage = DEFAULT_TABLE_PAGE_SIZE}) => {
    const filters = children;
    
    const { t } = useTranslation("common");

    const paginationComponentOptions = {
      rowsPerPageText: t('rowsPerPage'),
      rangeSeparatorText: t('of'),
      ...options
    }    

    return (
        <div className={ styles.dataTable }>
            { filters && filters.props.children }
            <LoaderWrapper>
              <div className={styles.tableWrapper}>
                <DataTable 
                  columns={ columns } 
                  data={ data } 
                  fixedHeader={fixedHeader}
                  pagination={pagination}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationTotalRows={paginationTotalRows}
                  paginationResetDefaultPage={resetPaginationToggle}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                  paginationPerPage={paginationPerPage}
                />
              </div>
            </LoaderWrapper>
        </div>
    );
};

Table.Filters = Filters;
export default Table;