import React  from 'react';
import styles from './threat.module.scss';
import { colors } from '../consts';
import { numberToString } from '../../../../utils/stringify';

const DonutTotals = ({ data, totalTitle }) => {
    return (
        <div className={ styles.totals }>
            { data.map((item) =>
                (<span key={ item.threatGroup }>
                    <div style={{ backgroundColor: colors[item.threatGroup] }} />
                    { item.threatGroup } - { item.totalBlocked } ({ (item.totalBlocked * 100 / data?.totalBlocked).toFixed(2) }%)
                </span>)
            ) }
            <p className='fw-600 nunito'>{ totalTitle } - { numberToString(data?.totalBlocked) }</p>
        </div>
    )
}

export default DonutTotals;