import { components } from "react-select"
import Country from "../Country"
import ReactTooltip from "react-tooltip"

export const countryOption = (css) => 
  (props) =>     
    <components.Option {...props}>
      <div className="flex">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label className={css}>
          {props.value ? <Country country={props.value} /> : props.label}       
        </label>
      </div>
    </components.Option>
     
export const countryMultiValueLabel = (css) => 
  (props) => 
    <components.MultiValueLabel {...props}>
      {props.data.label}
    </components.MultiValueLabel>

export const countryControl = (dataFor) => 
(props) => {
  return  (
    <>
      <ReactTooltip effect='solid' id={dataFor} type='light'>
          { props?.getValue()?.map(country => country.value ? <Country key={country.value} country={country.value}/> : country.label) }
      </ReactTooltip>
      <div data-tip data-for={dataFor}>
        <components.Control { ...props }></components.Control>
      </div>
    </>
  )
}
