import React, { useState }   from "react"
import { useSelector, useDispatch } from "react-redux"
import { filtersAction } from "../../../store/modules/filters/types"
import { filtersActionCreator } from "../../../store/modules/filters/action-creators"
import MobileView from "./Partials/MobileView"
import DesktopView from "./Partials/DesktopView"

const BlocksFilters = ({setCSVDownloaded}) => {    
  const [searchInput, setSearchInputValue] = useState("")    
  const [searchDelay, setSearchDelay]      = useState(null)    
  const isMobile = useSelector(state => state.generalReducer.isMobileResolution)
  const dispatch = useDispatch()

  const searchHandler = (event) => {
      const value = event?.target?.value
      setSearchInputValue(value)
      clearTimeout(searchDelay)
      setSearchDelay(setTimeout(() => {
          dispatch(filtersActionCreator.setFilter(value, filtersAction.SET_SEARCH_FILTER))
      }, 1000))
  }

  const clearSearch = () => {
      setSearchInputValue('')
      dispatch(filtersActionCreator.setFilter('', filtersAction.SET_SEARCH_FILTER))
  }  

  if(isMobile)
    return <MobileView clearSearchcb={ clearSearch } onChangecb={ searchHandler } />
  return <DesktopView searchInput={searchInput} clearSearchcb={ clearSearch } onChangecb={ searchHandler } />
}

export default BlocksFilters