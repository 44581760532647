import mixpanel from "mixpanel-browser";
import UAParser from "ua-parser-js";

export const TRACK_EVENTS = {
  BZClickedAddDomain: 'BZClickedAddDomain',
  BZSyncDomain: 'BZSyncDomain',
  BZAddNewDomain: 'BZAddNewDomain',
  BZEnabledPaidMarketing: 'BZEnabledPaidMarketing',
  BZDisabledPaidMArketing: 'BZDisabledPaidMArketing',
  BZClickDeleteDomain: 'BZClickDeleteDomain',
  BZConfirmDeletDomain: 'BZConfirmDeletDomain',
  BZPageView: 'BZPageView',
  BZOnboardingPluginPageSeen: 'BZOnboardingPluginPageSeen'
}

export const init = (clientId) => {
  const { browser, device, os } = new UAParser().getResult()  
  const mixpanelConfig = {
    ignore_dnt: true,
    loaded: (mixpanel) => {
      mixpanel.register_once({ browser, device, os, clientId })
      mixpanel.identify(clientId)
    }
  }
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, mixpanelConfig)
}

export const track = (event = TRACK_EVENTS.BZPageView, parameters) => {
  mixpanel.config && mixpanel.track(event, parameters)
}

export const trackABTesting = (event,featureStatus,  parameters) => {
const status =  {'BZOnboardingPluginTestV1':  featureStatus === 'on' ? ' the new version': 'the current version'}
const newParameters = {...parameters, status};
track(event, newParameters);
}