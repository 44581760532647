import Modal from "../common/modals/Modal";
import ModalBody from "../common/modals/ModalBody";
import styles from "./domainModal.module.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { X, ArrowLeftCircle } from "react-feather";
import AddDomain from "./AddDomain";
import DomainSelect from "./SelectDomain";
import CompleteSetup from "./CompleteSetup";
import Pages from "./Enums";
import LoaderWrapper from "../Loader/LoaderWrapper";
import ModalService from "../../services/ModalService";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const OnboardingModal = ({ closeOption = false, props }) => {
  const filteredDomains = useSelector(state => state.domainReducer.filterDomains) || [];
  const domainsLimit = useSelector(state => state.domainReducer.domainsLimit);
  const [currPage, SetPage] = useState(
    props?.id ? Pages.COMPLETE_SETUP : (!filteredDomains || filteredDomains.length === 0) && !!domainsLimit ? Pages.DOMAIN_ADD : Pages.DOMAIN_SELECT
  );
  const isMobile = useSelector(state => state.generalReducer.isMobileResolution);
  const { t } = useTranslation(["onboarding"]);
  const [modalTitle, SetTitle] = useState(t("selectWhichDomains"));
  const [bodyHeight, SetBodyHeight] = useState(isMobile ? "551px" : "591px");

  const closeModal = () => {
    ModalService.close(OnboardingModal);
  };

  return (
    <LoaderWrapper>
      <Modal>
        <ModalBody bodyClassName={styles.modalBody} bodyStyle={{ height: bodyHeight }}>
          {currPage !== Pages.COMPLETE_SETUP && (
            <div className={styles.modalHeader}>
              <p className="flex align-center">
                {currPage == Pages.DOMAIN_ADD && <ArrowLeftCircle onClick={() => SetPage(Pages.DOMAIN_SELECT)} className="cursor-pointer mr-9" size={16} />}
                <span className="fs-18 fw-600">{modalTitle}</span>
              </p>
              {closeOption && <X size={16} className={styles.closeButton} onClick={closeModal} />}
            </div>
          )}
          {currPage == Pages.DOMAIN_SELECT && <DomainSelect setPage={SetPage} setTitle={SetTitle} setBodyHeight={SetBodyHeight} />}
          {currPage == Pages.DOMAIN_ADD && <AddDomain setPage={SetPage} setTitle={SetTitle} setBodyHeight={SetBodyHeight} />}
          {currPage == Pages.COMPLETE_SETUP && <CompleteSetup id={props?.id} setPage={SetPage} setBodyHeight={SetBodyHeight} />}
        </ModalBody>
      </Modal>
    </LoaderWrapper>
  );
};
export default OnboardingModal;
