import Modal from "../../common/modals/Modal";
import ModalBody from "../../common/modals/ModalBody";
import React, { useState } from "react";
import { labelToRange, t } from "./consts";
import ModalService from "../../../services/ModalService";
import styles from "./staticRangesModal.module.scss"


export const StaticRangesModal = ({setSelectedRange}) => {
  const [value, setValue] = useState('');
  const closeModal = () => ModalService.pop();
  const handleDateSelection = (option) => {
      const optionValue = option.currentTarget.value;
      setValue(optionValue)
      if(optionValue === 'custom'){
        setSelectedRange([], true);
        closeModal();
        return;
      }
      const selectedRange = labelToRange[optionValue];
      setSelectedRange([selectedRange]);
      // maybe wait a bit before closing the modal
      closeModal();
    }

  return (
  <Modal>
    <ModalBody bodyClassName={styles.modalBody}>
      <div className={styles.modalHeader}>
      <p>Choose Date</p>
      </div>
      <div className={styles.optionsWrapper}>
        {Object.keys(labelToRange).map(label => {
          return (
            <div key={label} className={`flex ${styles.optionContainer}`}>
              <input type="radio" id={label} name={"range-option"} value={label} onClick={handleDateSelection} />
              <label className={styles.optionLabel} htmlFor={label}>{t(label)}</label>
            </div>
          )
        })}
        <div key="custom" className={`flex ${styles.optionContainer}`}>
          <input type="radio" id={"custom"} name={"range-option"} value={"custom"} onClick={handleDateSelection} />
          <label htmlFor={'custom'} className={styles.optionLabel}>{'Custom'}</label>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}