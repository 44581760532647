const summaryBase = {
  totalBlocked: 14,
  blockedPageloads: 0,
  blockedSiteInteractions: 0,
  totalVisits: 4900,
  invalidRate: 0.29,
  protectedValidVisits: 2417,
  protectedValidVisitsPercentage: 49.33,
  whiteListed: 335,
  whiteListedPercentage: 6.84,
  protectedVisits: 0
};
export const mockData = {
  domainOrNetworkIDHasData: true,
  fromDate: "2022-06-06",
  toDate: "2022-06-12",

  summary: {
    summary: [summaryBase],
    total: summaryBase
  },
  datedSummaries: [
    {
      totalBlocked: 0,
      blockedPageloads: 0,
      blockedSiteInteractions: 0,
      totalVisits: 263,
      invalidRate: 0,
      protectedValidVisits: 100,
      protectedValidVisitsPercentage: 0,
      whiteListed: 15,
      whiteListedPercentage: 0,
      protectedVisits: 100,
      date: "2022-06-12T00:00:00Z"
    },
    {
      totalBlocked: 3,
      blockedPageloads: 0,
      blockedSiteInteractions: 3,
      totalVisits: 635,
      invalidRate: 0.47,
      protectedValidVisits: 359,
      protectedValidVisitsPercentage: 0,
      whiteListed: 52,
      whiteListedPercentage: 0,
      protectedVisits: 362,
      date: "2022-06-11T00:00:00Z"
    },
    {
      totalBlocked: 1,
      blockedPageloads: 0,
      blockedSiteInteractions: 1,
      totalVisits: 731,
      invalidRate: 0.14,
      protectedValidVisits: 290,
      protectedValidVisitsPercentage: 0,
      whiteListed: 49,
      whiteListedPercentage: 0,
      protectedVisits: 291,
      date: "2022-06-10T00:00:00Z"
    },
    {
      totalBlocked: 4,
      blockedPageloads: 1,
      blockedSiteInteractions: 3,
      totalVisits: 627,
      invalidRate: 0.64,
      protectedValidVisits: 339,
      protectedValidVisitsPercentage: 0,
      whiteListed: 56,
      whiteListedPercentage: 0,
      protectedVisits: 342,
      date: "2022-06-09T00:00:00Z"
    },
    {
      totalBlocked: 4,
      blockedPageloads: 0,
      blockedSiteInteractions: 4,
      totalVisits: 884,
      invalidRate: 0.45,
      protectedValidVisits: 430,
      protectedValidVisitsPercentage: 0,
      whiteListed: 53,
      whiteListedPercentage: 0,
      protectedVisits: 434,
      date: "2022-06-08T00:00:00Z"
    },
    {
      totalBlocked: 2,
      blockedPageloads: 1,
      blockedSiteInteractions: 1,
      totalVisits: 877,
      invalidRate: 0.23,
      protectedValidVisits: 433,
      protectedValidVisitsPercentage: 0,
      whiteListed: 62,
      whiteListedPercentage: 0,
      protectedVisits: 434,
      date: "2022-06-07T00:00:00Z"
    },
    {
      totalBlocked: 0,
      blockedPageloads: 0,
      blockedSiteInteractions: 0,
      totalVisits: 883,
      invalidRate: 0,
      protectedValidVisits: 466,
      protectedValidVisitsPercentage: 0,
      whiteListed: 48,
      whiteListedPercentage: 0,
      protectedVisits: 466,
      date: "2022-06-06T00:00:00Z"
    }
  ],
  monthlySummaries: [
    {
      totalBlocked: 14,
      blockedPageloads: 2,
      blockedSiteInteractions: 12,
      totalVisits: 4900,
      invalidRate: 0.29,
      protectedValidVisits: 2417,
      protectedValidVisitsPercentage: 0,
      whiteListed: 335,
      whiteListedPercentage: 0,
      protectedVisits: 2429,
      month: "2022-6"
    }
  ],
  insights: [
    { totalBlocked: 7, threatGroup: "Invalid Bot Activity", threatType: "Scrapers", invalidRate: 46.67 },
    { totalBlocked: 2, threatGroup: "Invalid Bot Activity", threatType: "Malicious Bots", invalidRate: 13.33 },
    { totalBlocked: 6, threatGroup: "Invalid Bot Activity", threatType: "Automation tool", invalidRate: 40 }
  ],
  blocksByCountry: [
    { totalVisits: 3453, totalBlocked: 7, country: "IL", invalidRate: 46.67 },
    { totalVisits: 784, totalBlocked: 3, country: "US", invalidRate: 20 },
    { totalVisits: 136, totalBlocked: 2, country: "DE", invalidRate: 13.33 },
    { totalVisits: 0, totalBlocked: 1, country: "MX", invalidRate: 6.67 },
    { totalVisits: 0, totalBlocked: 1, country: "PF", invalidRate: 6.67 },
    { totalVisits: 1, totalBlocked: 1, country: "ES", invalidRate: 6.67 }
  ]
};
