import styles from "./domain-status.module.scss";
import { PLUGIN_URL, ROUTES, WORDPRESS_PLUGIN_PARTIAL_URL } from "../../../../constants/index";
import { domainsActionCreator } from "../../../../store/modules/domains/action-creators";
import { useDispatch } from "react-redux";
import Pages from "../../../Onboarding/Enums";
import { useHistory } from "react-router-dom";
import i18n from "../../../../i18n";
import { SplitContext } from "@splitsoftware/splitio-react";
import { useContext } from "react";
import { EVENT_ON, FEATURE_PLUGIN_LINK } from "../../../../services/splitIO/helpers";
import DomainStatusCol from "./DomainStatusCol";

const DomainStatus = ({ selectedDomain }) => {
  const t = i18n.getFixedT(null, "dashboardNavbar", "domainStatus");
  const { client } = useContext(SplitContext);
  const domainLinkTreatment = client.getTreatment(FEATURE_PLUGIN_LINK);
  const showDomainLink = domainLinkTreatment === EVENT_ON;
  const dispatch = useDispatch();
  const history = useHistory();
  const PLUGIN_WORDPRESS_URL = `//${selectedDomain.label}${WORDPRESS_PLUGIN_PARTIAL_URL}`;

  const updateMonitoring = async () => {
    selectedDomain.isMonitoring = false;
    await dispatch(domainsActionCreator.updateMonitoring(selectedDomain));
  };
  const showPluginConfiguration = () => {
    history.push(`${ROUTES.onboarding}/${selectedDomain.searchId}/${Pages.COMPLETE_SETUP}`);
  };
  const redirectToPlugin = () => {
    const url = showDomainLink ? PLUGIN_WORDPRESS_URL : PLUGIN_URL;
    window.open(url, "_blank");
  };

  return (
    <div>
      {selectedDomain.bzActive && !selectedDomain.bzPluginLatestVersion && !selectedDomain.isMonitoring && (
        <DomainStatusCol style={styles.pluginUpdateIcon} label={t("requiresUpdate")} onClick={redirectToPlugin} buttonText={t("update")} />
      )}
      {selectedDomain.bzActive && selectedDomain.bzPluginLatestVersion && !selectedDomain.isMonitoring && (
        <div>
          <span className={styles.protectedIcon} /> <label>{t("protected")}</label>
        </div>
      )}
      {!selectedDomain.bzActive && <DomainStatusCol style={styles.notProtectedIcon} label={t("notProtected")} onClick={showPluginConfiguration} buttonText={t("protectDomain")} />}
      {selectedDomain.bzActive && selectedDomain.isMonitoring && (
        <DomainStatusCol style={styles.protectedIcon} label={t("monitoringOnly")} onClick={updateMonitoring} buttonText={t("blockBots")} />
      )}
    </div>
  );
};

export default DomainStatus;
