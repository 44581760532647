import DataTable from "../../globals/DataTable/index";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import BlocksFilters from "../Filters";
import styles from "./blocks-table.module.scss";
import { Columns } from "./Columns";
import { useTranslation } from "react-i18next";
import Section from "../../common/Cards/Section";
import { filtersAction } from "../../../store/modules/filters/types";
import { filtersActionCreator } from "../../../store/modules/filters/action-creators";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../../constants";

const css = {
  body: styles.body,
  section: styles.section
};

const BlocksTable = () => {
  const { filters } = useSelector(state => state.filtersReducer);
  const [columns, setColumns] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { total: paginationTotalRows, blockedIps } = useSelector(state => state.blocksReducer.blocked);
  const { defaultColumns } = Columns();
  const { t } = useTranslation("blocksReport");
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.generalReducer.isLoading);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const onChangePage = useCallback(page => {
    const rowsProduct = rowsPerPage * page;
    if (rowsProduct < paginationTotalRows && blockedIps.length <= rowsProduct) dispatch(filtersActionCreator.setFilter(null, filtersAction.INCREMENT_OFFSET));
  });
  const onChangeRowsPerPage = useCallback((rowsPerPage, currentPage) => {
    setRowsPerPage(rowsPerPage);
    if (blockedIps.length < rowsPerPage * currentPage) dispatch(filtersActionCreator.setFilter(0, filtersAction.SET_OFFSET));
  });

  useEffect(() => {
    if (!filters.offset) setResetPaginationToggle(!resetPaginationToggle);
  }, [filters.offset]);

  useEffect(() => {
    const columns = !!filters.filter ? defaultColumns().filter(item => item.name !== "Domain") : defaultColumns();
    setColumns(columns);
  }, [filters.search]);

  return (
    <div className={styles.blocksTable}>
      <Section
        title={t("title")}
        css={css}
        body={
          <DataTable
            columns={columns}
            data={blockedIps}
            pagination={true}
            paginationTotalRows={paginationTotalRows}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            loading={isLoading}
            resetPaginationToggle={resetPaginationToggle}
          >
            <DataTable.Filters>
              <BlocksFilters />
            </DataTable.Filters>
          </DataTable>
        }
      />
    </div>
  );
};

export default BlocksTable;
