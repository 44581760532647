import { EAuthAction } from './types';

const initialState = {
  user: {}
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case EAuthAction.SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}