import styles from "./section.module.scss";
const Section = ({ title, body, header, css = {}, warning = "" }) => {
  return (
    <section className={`${css.section} ${styles.section}`}>
      { title && <div className={styles["title-container"]}>
        <h1 className={styles.title}>{title}</h1>
        {warning && <div className={styles.warning}>{warning}</div>}
      </div> }
      <div className={`${styles.body} ${css.body}`}>
        {header && <section className={styles.header}>{header}</section>}
        {body}
      </div>
    </section>
  );
};
export default Section;