import React, { memo, useState, useCallback, useMemo } from 'react';
import SearchInput from '../../../globals/SearchInput';
import { Filter, Search } from "react-feather";
import styles from "../filters.module.scss"
import { apps } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import ModalService from '../../../../services/ModalService';
import FiltersModal from './FiltersModal';

const iconsSize = 16
const CLEARE_ICON_CLASS = "clear-icon"
const css = {
  wrapper: styles.search,
  [CLEARE_ICON_CLASS]: styles[CLEARE_ICON_CLASS]
}

const openFilterModal = () => ModalService.open(FiltersModal)

const MobileView = memo(({searchInput, clearSearchcb, onChangecb}) => {
  const [showSearchInput, setShowSearchInput] = useState(false) 
  const toggleSearch = () => setShowSearchInput(!showSearchInput)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const toggleFilterModal = () => setShowFiltersModal(!showFiltersModal)
  const { t } = useTranslation("sideBar")

  const onClearSearch = useCallback(() => {
    if(!searchInput)
      toggleSearch()
    clearSearchcb()
  }, [searchInput, showSearchInput])

  const searchInputComponent = useMemo(() => (    
    <SearchInput 
      searchInput={ searchInput } 
      clearSearchcb={ onClearSearch } 
      onChangecb={ onChangecb }
      placeholder="" 
      icon={false} 
      showClear={true}
      clearIconSize={18}
      css={css}
      focus
    />
  ), [searchInput, showSearchInput])

  const baseSearchNavbar = (
    <section className={styles.navbar}>
      <span className={styles['navbar-title']}>{apps.BOT_ZAPPING} | {t('blocksReport')}</span>
      <Search size={iconsSize} onClick={toggleSearch}/>
    </section>
  )
  
  return (
    <section className={styles.blocksFilters}>      
      { showSearchInput ? searchInputComponent : baseSearchNavbar }
      <Filter size={iconsSize} onClick={openFilterModal}/>
    </section>
  );
});

export default MobileView;