import React, {useEffect} from "react";
import styles from "./charts.module.scss";
import Chart  from 'react-apexcharts';
import { useSelector } from "react-redux";

const DonutChart = ({ data }) => {
    const isMobile = useSelector(state => state.generalReducer.isMobileResolution)

    const options = {
        legend: { show: false },
        dataLabels: { enabled: false },
        colors: data?.colors,
        labels: data?.labels ? data.labels : [],
        plotOptions: {
            pie: {
                expandOnClick: false
            }
        }
    }

    const height = isMobile? 130: 206;

    return (
        <div className={ styles.donut }>
            <Chart options={ options } series={ data?.series ? data.series : [] } type="donut" height={ height } />
        </div>
    )
}

export default DonutChart;