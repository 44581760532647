import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChevronDown, Menu }   from 'react-feather'
import { generalActionCreator } from "../../../store/modules/general/action-creators"
import { CC_LINK } from './consts'
import styles from './navbar.module.scss'
import logo   from '../../../assets/images/small_logo.svg'
import { useTranslation } from "react-i18next"
import { useLocalStorageUser } from "../../../utils/User"
import { LogOut } from "react-feather"
import { useHistory } from "react-router-dom"
import { apps } from "../../../constants"

const Navbar = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("common")
    
    const isMobile = useSelector(state => state.generalReducer.isMobileResolution)
    const sidebarIsOpen = useSelector(state => state.generalReducer.sidebarIsOpen)
    const { getLocalStorageUser, clearLocalStorageUser  } = useLocalStorageUser();
    const { showLogout } = getLocalStorageUser()
    const onAppChange = (app) => {
        if(app === apps.BOT_ZAPPING) return
        window.open(CC_LINK, '_blank').focus()
    }
    const openSidebar = () => dispatch(generalActionCreator.setSidebar(true))
    const setStyles = () => {
        const navbar = document.getElementById("app_navbar")
        const menu = document.getElementById("sidebar_button")
        navbar.style.left = sidebarIsOpen && !isMobile ? '238px' : '0'
        navbar.style.width = sidebarIsOpen && !isMobile ? 'calc(100% - 238px)' : '100%'
        menu.style.width = sidebarIsOpen ? '0' : '25px'
        menu.style.marginLeft = sidebarIsOpen ? '0' : '25px'
    }
    const history = useHistory()
    const logout = () => {
      clearLocalStorageUser()
      history.push('/admin-login')
    }

    useEffect(setStyles, [sidebarIsOpen])

    return (
        <div id='app_navbar' className={ styles.navbar }>
          <section className="flex">
            <Menu id='sidebar_button' onClick={ openSidebar } />
            <div className={ styles.appSelector }>
                <span>
                    { apps.BOT_ZAPPING }
                    <ChevronDown size={ 14 } style={{ marginLeft: '5px' }} />
                </span>
                <ul>
                    { Object.values(apps).map(app => (
                        <li key={ app }>
                            <a onClick={ () => { onAppChange(app) } } className={app.class || "color-unset"}>
                                { app.key ? t(app.key) : app }
                            </a>
                        </li>
                    )) }
                    <li>
                        <p>
                            <img src={ logo } alt='clickcease-logo' />
                            ClickCease Suite
                        </p>
                    </li>
                </ul>
            </div>
          </section>
          { showLogout && <section className={styles.logout} onClick={logout}><div>{t('logout')}</div><LogOut width={16}/></section>}
        </div>
    )
}

export default Navbar