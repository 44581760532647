import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from "../../globals/Select"
import { filtersActionCreator } from "../../../store/modules/filters/action-creators"
import { filtersAction } from "../../../store/modules/filters/types"

const ThreatGroup = memo(() => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["blocksReport", "common"])
  const invalidBotActivity = t('invalidBotActivity', { ns: "common" })
  const invalidMaliciousActivity = t('invalidMaliciousActivity', { ns: "common" })
  const filters  = useSelector((state) => state.filtersReducer.filters)
  const groupOptions = [
    { label: t("options.allGroups"), value: "" },
    { label: invalidBotActivity, value: "Invalid Bot Activity" },
    { label: invalidMaliciousActivity, value: "Invalid Malicious Activity" }
  ]
  const [groupFilter, setGroupFilter] = useState(groupOptions[0])
  const setGroup = (group) => {
    setGroupFilter(group)
    dispatch(filtersActionCreator.setFilter(group.value, filtersAction.SET_GROUP_FILTER))
  }

  useEffect(() => {
    setGroupFilter(groupOptions.find(item => item.value === filters.group))        
  }, [filters.group])

  return (
    <div className='flex'>
      <span className="mr-8 fs-14">{t("filters.threatGroups")}</span>
      <Select minWidth='210px' changeHandler={ setGroup } value={ groupFilter } options={ groupOptions } />
    </div>
  )
})

export default ThreatGroup