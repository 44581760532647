import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import { useTranslation } from "react-i18next"
import "./style.scoped.scss"

const ClipboardCopySetup = ({ number, copyText, title, fullWidth = true, css, dataContainerCSS}) => {
  const [randomID, setRandomID] = useState(String(Math.random()))
  const [wasCopied, setWasCopied] = useState(false);

  const { t } = useTranslation("onboarding", "common");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText)
    setWasCopied(true);
    setTimeout(() => {
      setWasCopied(false)
    }, 5000)
  }
  
  const getMessage = () => {
    return !wasCopied? t("clickToCopy"): t("copied", {ns: "common"})
 }

 const handleFocus = (event) => {
  const { currentTarget } = event;
  setTimeout((currTarget)=> {
    currTarget.select()
  }, 0, currentTarget)
 }

 const background = getComputedStyle(document.documentElement).getPropertyValue('--background-blue').trim()
 const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text-blue').trim()

 return (
    <div className={`${css} flex`}>
      <ReactTooltip html={true} type="light" id={randomID} getContent={getMessage} backgroundColor={background} textColor={textColor}/>
      <div className={dataContainerCSS}>
        {number && <p className="round-shadow inline-block fs-14">{number}</p>}
        {title && <label className="fs-14">{title}</label>}
        <input readOnly={true} value={copyText} data-tip={getMessage()} onMouseOver={handleFocus} onMouseLeave={e => {
          if(e.currentTarget){ e.currentTarget.blur()}}} data-for={randomID} onClick={copyToClipboard} className="plugin-key" style={{ width: fullWidth ? 'unset' : '112px' }} />
        <div className="copy__section position-relative inline-block">
        </div>
      </div>
    </div>
  )
}

export default ClipboardCopySetup
