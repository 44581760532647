import React, { useEffect } from "react";

const Login = () => {
  useEffect(() => {
    window.location.href = "https://www.clickcease.com/dashboard/#/login";
  }, []);

  return <div className="login-page" />;
};

export default Login;
