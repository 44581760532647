import { SplitSdk } from "@splitsoftware/splitio-react";

export const createSDK = key => {
  return SplitSdk({
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_ENV,
      key: key
    },
    scheduler: {
      eventsPushRate: 5,
      impressionsRefreshRate: 5,
      metricsRefreshRate: 5
    },
    streamingEnabled: true,
    debug: false
  });
};

export const UNDEFINED_EVENT_VALUE = "control";
export const FEATURE_PLUGIN_LINK = "BZ_PLUGIN_WORDPRESS_LINK";
export const FEATURE_DOMAIN_STATUS = "BZ_SHOW_DOMAIN_STATUS";
export const EVENT_ON = "on";
