import LoaderWrapper from "../../components/Loader/LoaderWrapper";
import ProtectionSetupBanner from "../../components/ProtectionSetup/Banner";
import ProtectionSetupTable from "../../components/ProtectionSetup/Table";

const ProtectionSetup = () => {
  return (
    <div>
      <LoaderWrapper>
        <ProtectionSetupBanner />
        <ProtectionSetupTable />
      </LoaderWrapper>
    </div>
  );
};

export default ProtectionSetup;
