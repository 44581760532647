import { modalActions } from "./types";

export const modalActionCreator = {
  [modalActions.OPEN]: modal => ({
    type: modalActions.OPEN,
    payload: modal
  }),
  [modalActions.CLOSE]: () => ({
    type: modalActions.CLOSE
  }),
  [modalActions.PUSH]: modal => ({
    type: modalActions.PUSH,
    payload: modal
  }),
  [modalActions.POP]: () => ({
    type: modalActions.POP
  })
};
