import React from "react";
import { useSelector } from "react-redux";
import Highlight from "react-highlighter";
import { parse } from "date-fns";
import { DDMMYYYYHHMMSS } from "../../../constants";
import i18n from "../../../i18n";
import Country from "../../common/Country";
import { threatGroups } from "../../Dashboard/Insights/consts";
import ReactTooltip from "react-tooltip";
const blocksReportT = i18n.getFixedT(null, "blocksReport", "table.header");
const commonT = i18n.getFixedT(null, "common");

export const Columns = () => {
  const searchText = useSelector(state => state.filtersReducer.filters.search);
  const translatedThreatGroups = {
    [threatGroups.INVALID_BOT_ACTIVITY]: commonT("invalidBotActivity"),
    [threatGroups.INVALID_MALICIOUS_ACTIVITY]: commonT("invalidMaliciousActivity")
  };

  const translatedThreatTypes = commonT("treatTypes", { returnObjects: true });

  const sortByDate = (rowA, rowB) => {
    const dateA = parse(rowA.lastSeen, DDMMYYYYHHMMSS, new Date());
    const dateB = parse(rowB.lastSeen, DDMMYYYYHHMMSS, new Date());

    return dateB - dateA;
  };

  const defaultColumns = () => [
    {
      name: blocksReportT("ip"),
      selector: row => row.ip,
      width: "160px",
      sortable: true,
      cell: row => (
        <Highlight matchClass="highlight" search={searchText}>
          {row.ip}
        </Highlight>
      )
    },
    {
      name: blocksReportT("lastSeen"),
      selector: row => row.lastSeen,
      width: "200px",
      sortable: true,
      sortFunction: sortByDate
    },
    {
      name: blocksReportT("threatGroup"),
      selector: row => translatedThreatGroups[row.threatGroup],
      width: "190px",
      sortable: true
    },
    {
      name: blocksReportT("threatType"),
      selector: row => translatedThreatTypes[row.threatType],
      width: "180px",
      sortable: true
    },
    {
      name: "Action Taken",
      selector: row => row.monitored,
      width: "140px",
      sortable: true,
      cell: row => (
        <Highlight matchClass="highlight" search={searchText}>
          {row.monitored ? "Monitored only" : "Blocked"}
        </Highlight>
      )
    },
    {
      name: commonT("country"),
      selector: row => row.country,
      width: "240px",
      cell: row => (row.country ? <Country country={row.country} /> : ""),
      sortable: true
    },
    {
      name: commonT("domain"),
      selector: row => row.domain,
      width: "200px",
      sortable: true,
      cell: row => (
        <Highlight matchClass="highlight" search={searchText}>
          {row.domain}
        </Highlight>
      )
    },
    {
      name: blocksReportT("url"),
      selector: row => row.url,
      id: "url-column",
      sortable: true,
      cell: row => (
        <>
          <ReactTooltip effect="solid" id={"tooltip-url" + row?.lastSeen?.replace(/ /g, "")} multiline type="light" />
          <Highlight matchClass="highlight" search={searchText} className="shrunken-text" data-tip={row.url} data-for={"tooltip-url" + row?.lastSeen?.replace(/ /g, "")}>
            {row.url}
          </Highlight>
        </>
      )
    }
  ];

  return {
    defaultColumns
  };
};
