import authReducer from "./modules/auth/reducer";
import blocksReducer from "./modules/blocks/reducer";
import generalReducer from "./modules/general/reducer";
import filtersReducer from "./modules/filters/reducer";
import summaryReducer from "./modules/summary/reducer";
import domainReducer from "./modules/domains/reducer";
import tagsReducer from "./modules/tags/reducer";
import modalReducer from "./modules/modal/reducer";

export default {
  generalReducer,
  authReducer,
  blocksReducer,
  filtersReducer,
  summaryReducer,
  domainReducer,
  tagsReducer,
  modalReducer
};
