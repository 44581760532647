import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Navbar";
import Dashboard from "../../../views/Dashboard";
import BlocksReport from "../../../views/BlocksReport";
import { domainsActionCreator } from "../../../store/modules/domains/action-creators";
import styles from "./view.module.scss";
import ProtectionSetup from "../../../views/ProtectionSetup";
import OnBoarding from "../../../views/Onboarding";
import { TRACK_EVENTS, track } from "../../../services/tracking";

const View = () => {
  const [isOnline, setIsOnline] = useState(false);
  const dispatch = useDispatch();
  const sidebarIsOpen = useSelector(state => state.generalReducer.sidebarIsOpen);

  useEffect(async () => {
    await dispatch(domainsActionCreator.getBotzaDomains());
    dispatch(domainsActionCreator.getDomainsLimits());
    setIsOnline(true);
  }, []);

  useEffect(() => (document.getElementById("app_view").style.marginLeft = sidebarIsOpen ? "238px" : "0"), [sidebarIsOpen]);

  let location = useLocation();
  useEffect(() => {
    track(TRACK_EVENTS.BZPageView, location);
  }, [location]);

  return (
    <div style={{ width: "100%" }}>
      <Navbar />
      <div id="app_view" className={styles.view}>
        {isOnline && (
          <Switch>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/blocks-report">
              <BlocksReport />
            </Route>
            <Route path="/protection-setup">
              <ProtectionSetup />
            </Route>
            <Route path="/onboarding/:id?/:stepId?">
              <OnBoarding />
            </Route>
          </Switch>
        )}
      </div>
    </div>
  );
};

export default View;
