import { domainsAction } from "./types";
import i18n from "../../../i18n";
const initialState = {
  nonBotzaDomains: [],
  botzaDomains: [],
  filterDomains: null,
  domainsChecked: null,
  noDomains: false,
  filterBotzaDomains: [],
  selectBotzaDomains: [],
  domainsLimit: 0,
  selectedDomain: null
};

export default function domainReducer(state = initialState, action) {
  switch (action.type) {
    case domainsAction.SET_BOTZA_DOMAINS:
      const nonBotzaDomains = [];
      const botzaDomains = [];

      action.payload.map(domain => {
        if (domain.isBotZappingEnabled) botzaDomains.push(domain);
        else nonBotzaDomains.push({ label: domain.domain, selected: state.domainsChecked ? state.domainsChecked.some(checked => checked === domain.domain) : false });
      });
      const noDomains = !botzaDomains?.length;
      const t = i18n.getFixedT(null, "common");
      const allDomainsElement = { label: t("allDomains"), value: "" };
      const selectBotzaDomains = botzaDomains.map(domain => ({
        label: domain.domain,
        value: domain.domain,
        bzActive: domain.bzActive,
        bzPluginLatestVersion: domain.bzPluginLatestVersion,
        isMonitoring: domain.isMonitoring,
        searchId: domain.searchId,
        hash: domain.hash,
        apiKey: domain.apiKey
      }));
      if (selectBotzaDomains?.length > 1) selectBotzaDomains.unshift(allDomainsElement);

      return {
        ...state,
        nonBotzaDomains,
        botzaDomains,
        noDomains,
        selectBotzaDomains,
        filterDomains: nonBotzaDomains,
        filterBotzaDomains: botzaDomains,
        selectedDomain: !!state.selectedDomain ? state.selectedDomain : selectBotzaDomains.length ? selectBotzaDomains[0] : allDomainsElement
      };
    case domainsAction.SET_NO_DOMAINS:
      return {
        ...state,
        noDomains: action.payload
      };
    case domainsAction.ADD_SELECTED_DOMAIN:
      return {
        ...state,
        selectBotzaDomains: [...state.selectBotzaDomains, action.payload]
      };
    case domainsAction.SET_DOMAINS_LIMITS:
      return {
        ...state,
        domainsLimit: action.payload
      };
    case domainsAction.ADD_DOMAIN:
      return {
        ...state,
        filterDomains: [...state.filterDomains, action.payload]
      };
    case domainsAction.FILTER_DOMAIN:
      return {
        ...state,
        filterDomains: state?.nonBotzaDomains?.filter(domain => domain.label.includes(action.payload))
      };
    case domainsAction.SET_CHECKED_DOMAIN:
      const newDomains = state.nonBotzaDomains.map(domain => {
        const isSelected = action.payload.some(selectedDomain => selectedDomain === domain.label);
        return { label: domain.label, selected: isSelected };
      });

      const newFilteredDomains = state.filterDomains.map(domain => {
        const isSelected = action.payload.some(selectedDomain => selectedDomain === domain.label);
        return { label: domain.label, selected: isSelected };
      });

      return {
        ...state,
        domainsChecked: action.payload,
        nonBotzaDomains: newDomains,
        filterDomains: newFilteredDomains
      };
    case domainsAction.SORT_DOMAINS:
      return {
        ...state,
        botzaDomains: state?.botzaDomains?.sort((a, b) => (a.domain < b.domain ? -1 : 1) * (action.payload ? 1 : -1))
      };
    case domainsAction.FILTER_BOTZA_DOMAIN:
      return {
        ...state,
        filterBotzaDomains: action.payload ? state.botzaDomains.length && state.botzaDomains.filter(domain => domain.domain.includes(action.payload)) : state.botzaDomains
      };
    case domainsAction.SET_SELECTED_DOMAIN:
      return {
        ...state,
        selectedDomain: state.selectBotzaDomains.find(domain => domain.value === action.payload)
      };
    default:
      return state;
  }
}
