import { getStorageItem, setStorageItem } from "./storage";
import { LOCAL_STORAGE_USER_KEY, LOCAL_STORAGE_USER_KEY_TEMP, DEFAULT_LANGUAGE } from "../constants";

export const useLocalStorageUser = () => {
  const setLocalStorageUser = ({ id, token, language = DEFAULT_LANGUAGE, showLogout = false, clientId }) => {
    if (id && token) setStorageItem(LOCAL_STORAGE_USER_KEY, JSON.stringify({ id, token, language, showLogout, clientId }));
  };
  const clearLocalStorageUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY_TEMP);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  };
  const getLocalStorageUser = () => {
    return getStorageItem(LOCAL_STORAGE_USER_KEY) || getStorageItem(LOCAL_STORAGE_USER_KEY_TEMP) || {};
  };
  const getToken = () => getLocalStorageUser()?.token;
  const getId = () => getLocalStorageUser()?.id;

  return {
    setLocalStorageUser,
    getLocalStorageUser,
    getToken,
    clearLocalStorageUser,
    getId
  };
};
