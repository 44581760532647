import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, AlertCircle, Search } from "react-feather";
import CheckboxGroup from "react-checkbox-group";
import Highlight from "react-highlighter";
import { domainsActionCreator } from "../../../store/modules/domains/action-creators";
import { tagsActionCreator } from "../../../store/modules/tags/action-creators";
import { filtersActionCreator } from "../../../store/modules/filters/action-creators";
import { filtersAction } from "../../../store/modules/filters/types";
import SearchInput from "../../globals/SearchInput";
import Pages from "../Enums";
import "./style.scoped.scss";
import { useTranslation } from "react-i18next";
import { TRACK_EVENTS, track } from "../../../services/tracking";

const DomainSelect = ({ setPage, setTitle }) => {
  const { t } = useTranslation(["onboarding", "common"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const filteredDomains = useSelector(state => state.domainReducer.filterDomains) || [];
  const domainsChecked = useSelector(state => state.domainReducer.domainsChecked) || [];
  const domainsLimit = useSelector(state => state.domainReducer.domainsLimit);
  const isMobile = useSelector(state=>state.generalReducer.isMobileResolution);
  const [showSearch, setShowSearch] = useState(!isMobile);
  const [searchInput, setInputValue] = useState("");
  const [validationError, setValidationError] = useState(domainsChecked.length === 0 ? t("noCheckedDomainsError") : t("onlyWordpressDomainsMessage"));

  const search = e => {
    dispatch(domainsActionCreator.filterDomain(e.target.value));
    setInputValue(e.target.value);
  };
  const clearSearch = () => {
    setInputValue("");
    dispatch(domainsActionCreator.filterDomain(""));
  };
  const selectAll = e => {
    if (e.target.checked) {
      const filteredDomainsNames = filteredDomains.map(domain => domain.label);
      dispatch(domainsActionCreator.setCheckedDomains(filteredDomainsNames));
      setValidationError(t("onlyWordpressDomainsMessage"));
    }
    else {
      dispatch(domainsActionCreator.setCheckedDomains([]));
      setValidationError(t("noCheckedDomainsError"));
  };
}
  const updateDomains = async () => {
    const { data, error } = await dispatch(domainsActionCreator.updateDomains(domainsChecked));
    if (data) {
      
      dispatch(domainsActionCreator.setNoDomains(false));
      const domain = domainsChecked[0];
      await dispatch(tagsActionCreator.getTagsAndDomains(domain));
      const objectDomain = { label: domain, value: domain };
      dispatch(domainsActionCreator.addSelectedDomain(objectDomain));
      dispatch(domainsActionCreator.setSelectedDomain(objectDomain));
      dispatch(filtersActionCreator.setFilter(domain, filtersAction.SET_DOMAIN_FILTER));
      dispatch(domainsActionCreator.setCheckedDomains([]));
      track(TRACK_EVENTS.BZSyncDomain)
      history.push("/dashboard");
      setPage(Pages.COMPLETE_SETUP);
    } else setValidationError(error?.message);
  };
  const updateCheckedDomains = domainsChecked => {
    dispatch(domainsActionCreator.setCheckedDomains(domainsChecked));
    if (domainsChecked.length === 0) setValidationError(t("noCheckedDomainsError"));
    else setValidationError(t("onlyWordpressDomainsMessage"))
  };

  useEffect(async () => {
    const { error } = await dispatch(domainsActionCreator.getBotzaDomains());
    if (error?.message) setValidationError(t("getDomainNetworkError"));
  }, []);

  useEffect(() => setTitle(t("selectWhichDomains")), []);

  const containerClasses =  showSearch ? "domain-select search-open": "domain-select";
  const bottomContainerClasses = domainsChecked.length ? "bottom-container has-selected-options": 'bottom-container'
  return (
    <div className={containerClasses}>
      <div className="flex wrap colmun pl-27 top-container">
        <label className="flex">
          <input type="checkbox" className="mr-8" onChange={selectAll} checked={filteredDomains?.length === domainsChecked?.length}/>
          <span className="mt-1 fw-600">{filteredDomains?.length} {filteredDomains?.length === 1
          ?  t("domain", {ns: 'common'}) : t("domains", {ns: 'common'})}</span>
        </label>
        <div className="flex right-side-container">
        <div className="flex search-icon" onClick={() => {setShowSearch(true)}}>
            <Search size={16} />
              </div>
        {!!domainsLimit &&
          <>
            <span className="divider">|</span>
            <div className="flex cursor-pointer add-domain" onClick={() => setPage(Pages.DOMAIN_ADD)}>
              <PlusCircle size={16} />
              <span className="cursor-pointer px-5">{t("addNewDomain")}</span>
            </div>
          </>
        }
         </div>
        {showSearch && <SearchInput className={'search-container'} searchInput={searchInput} clearSearchcb={clearSearch} onChangecb={search} />}
      </div>
      <div className="domains">
        <CheckboxGroup name="domains" value={domainsChecked} onChange={updateCheckedDomains}>
          {Checkbox => (
            <>
              {filteredDomains.map((domain, index) => {
                const labelClassNames = domain.selected? "flex checked": "flex";
                return (
                <label className={labelClassNames} key={index}>
                   <Checkbox className="mr-5" value={domain.label} />{" "}
                  <Highlight matchClass="highlight" search={searchInput}>
                    {domain.label}
                  </Highlight>
                </label>)
})}
            </>
          )}
        </CheckboxGroup>
      </div>
      <div className={bottomContainerClasses}>
        {validationError && (
          <label className="m-20 position-absolute bottom-left fs-14">
            <AlertCircle size={16} className="alert-icon" />
            <span>{validationError}</span>
          </label>
        )}

        <button
          className={`save-domain ${!domainsChecked.length ? "disabled" : ""} m-20 position-absolute bottom-right cursor-pointer`}
          disabled={!domainsChecked.length}
          onClick={updateDomains}
        >
          <PlusCircle size={16} style={{ marginRight: ".25rem" }} />
          {t("addSelected")} {domainsChecked.length ? `(${domainsChecked.length})` : ""}
        </button>
      </div>
    </div>
  );
};

export default DomainSelect;
