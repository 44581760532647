import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styles from "./blocks-report.module.scss";
import MobileView from "./Partials/MobileView";
import DesktopView from "./Partials/DesktopView";
import { blocksActionCreator } from "../../store/modules/blocks/action-creators";

const BlocksReport = () => {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({})
  const stateFilters = useSelector(state => state.filtersReducer.filters)
  const isMobile = useSelector(state => state.generalReducer.isMobileResolution)  
  const botzaDomains = useSelector(state => state.domainReducer.botzaDomains)

  const fetch = () => {
    if (!shallowEqual(filters, stateFilters)) {
      setFilters(stateFilters)
      dispatch(blocksActionCreator.updateBlockedIps())
    }
  }

  const dependencies = [botzaDomains];
  if(!isMobile)
    dependencies.push(stateFilters)

  useEffect(fetch, dependencies)

  if (isMobile) return <MobileView className={styles.blocks}/>;
  return <DesktopView className={styles.blocks}/>;
};

export default BlocksReport;
