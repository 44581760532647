import React, { useState } from 'react'
import ReactTooltip from "react-tooltip"
import { Info, ChevronsRight } from 'react-feather'
import styles from "./tooltip.module.scss"
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
const Tooltip = ({ mainContent, extendedContent, expandText, collapseText, delayHide = 1000 }) => {
  const { t } = useTranslation("common");
  const [showMore, setShowMore] = useState(true)
  const toggleShowMore = (e) => {
    e.preventDefault()
    setShowMore(!showMore)
  }
  const id = uniqueId();
  const expand = expandText || t("learnMore")
  const collapse = collapseText || t("showLess")
  return (
    <div>
      <ReactTooltip className={styles.tooltip} id={id} clickable effect='solid' multiline type='light' delayHide={delayHide}>
        <div className={styles.body}>
          <div className={styles.main}>{showMore && mainContent}</div>
          {extendedContent &&
            <>
              <span className={styles.toggle_view} onClick={toggleShowMore}>
                {showMore ? expand : collapse}
                <ChevronsRight size={12} />
              </span>
              {!showMore && extendedContent}
            </>
          }
        </div>
      </ReactTooltip>
      <div className={styles.trigger}>
        <Info data-tip data-for={id} />
      </div>
    </div>
  )
}

export default Tooltip