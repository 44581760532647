import React, { useEffect, useState } from "react";
import styles from "./types.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import Tooltip from "../../../common/Tooltip";
import { colors, threatGroups, defaultState } from "../consts";

const ThreatTypes = () => {
  const [pieChartData, setPieChartData] = useState([]);
  const [chartsData, setChartsData] = useState(null);
  const [chartsOptions, setChartOptions] = useState(defaultState);

  const { t: dashboardT } = useTranslation("dashboard", { keyPrefix: "insights" });
  const { t: categoriesT } = useTranslation("dashboard", { keyPrefix: "insights.threatTypes.categories" });
  const { t: commonT } = useTranslation(["common"]);
  const invalidBotActivityTitle = commonT("invalidBotActivity");
  const invalidMaliciousActivity = commonT("invalidMaliciousActivity");
  const translatedThreatTypes = commonT("treatTypes", { returnObjects: true });
  const invalidBotActivity = "Invalid Bot Activity";
  const insights = useSelector(state => state.summaryReducer.summary.insights);
  const threatTypes = useSelector(state => state.summaryReducer.summary.threatTypes);
  const isMobile = useSelector(state => state.generalReducer.isMobileResolution);
  const GRAY = "#EFEFEF";
  const columns = [
    { type: "string" },
    { type: "number" },
    {
      type: "string",
      role: "annotation"
    }];
  const baseData = ["Pac Man", "Percentage"];

  const createPieChartData = () => {
    const pieChartData = insights.map(insight => {
      const currData = [];
      const baseOptions = {
        legend: "none",
        pieSliceText: "none",
        tooltip: { trigger: "none" },
        backgroundColor: "transparent",
        slices: {
          0: { color: GRAY },
          1: { color: colors[threatGroups.INVALID_BOT_ACTIVITY] }
        }
      };
      currData.push(baseData);
      if (insight.threatGroup !== threatGroups.INVALID_BOT_ACTIVITY) {
        baseOptions.slices[0] = { color: colors[threatGroups.INVALID_MALICIOUS_ACTIVITY] };
        baseOptions.slices[1] = { color: GRAY };
        currData.push(["", insight.totalBlocked]);
        currData.push(["", insights.totalBlocked - insight.totalBlocked]);
      } else {
        currData.push(["", insights.totalBlocked - insight.totalBlocked]);
        currData.push(["", insight.totalBlocked]);
      }
      currData.insight = insight;

      return {
        options: baseOptions,
        data: currData
      };
    });
    setPieChartData(pieChartData);
  };

  const createChartsData = () => {
    const chartData = [];
    const invalidBotActivityThreats = threatTypes.filter(threatType => threatType.threatGroup === invalidBotActivity);
    const invalidMaliciousThreats = threatTypes.filter(threatType => threatType.threatGroup !== invalidBotActivity);

    if (invalidMaliciousThreats?.length) {
      let chart = invalidMaliciousThreats.map(invalid => {
        return [translatedThreatTypes[invalid.threatType], invalid.invalidRate, `${invalid.invalidRate}%`];
      });
      chart.color = colors[threatGroups.INVALID_MALICIOUS_ACTIVITY]
      chart.threatGroup = threatGroups.INVALID_MALICIOUS_ACTIVITY;
      chartData[threatGroups.INVALID_MALICIOUS_ACTIVITY] = chart;
    }
    if (invalidBotActivityThreats?.length) {
      let chart = invalidBotActivityThreats.map(invalid => {
        return [translatedThreatTypes[invalid.threatType], invalid.invalidRate, `${invalid.invalidRate}%`];
      });
      chart.color = colors[threatGroups.INVALID_BOT_ACTIVITY]
      chart.threatGroup = threatGroups.INVALID_BOT_ACTIVITY;
      chartData[threatGroups.INVALID_BOT_ACTIVITY] = chart;
    }

    if (chartData[threatGroups.INVALID_MALICIOUS_ACTIVITY]?.length) chartData[threatGroups.INVALID_MALICIOUS_ACTIVITY].unshift(columns);
    if (chartData[threatGroups.INVALID_BOT_ACTIVITY]?.length) chartData[threatGroups.INVALID_BOT_ACTIVITY].unshift(columns);

    if (chartData.length === 1) {
      chartsOptions.width = 500;
      (chartsOptions.chartArea.width = "100%"), setChartOptions(chartsOptions);
    }
    if (isMobile) {
      chartsOptions.width = 330;
      chartsOptions.chartArea.right = 45;
      chartsOptions.chartArea.left = 150;
    }
    setChartsData(chartData);
  };

  useEffect(createPieChartData, [insights]);
  useEffect(createChartsData, [threatTypes]);

  const toolitipShowMore = (
    <span>
      <br />
      <b>{categoriesT("scrapers.title")}</b>
      <br />
      {categoriesT("scrapers.explanation")}
      <br />
      <br />
      <b>{categoriesT("automationTools.title")}</b>
      <br />
      {categoriesT("automationTools.explanation")}
      <br />
      <br />
      <b>{categoriesT("excessiveRateLimit.title")}</b>
      <br />
      {categoriesT("excessiveRateLimit.explanation")}
      <br />
      <br />
      <b>{categoriesT("falseRepresentation.title")}</b>
      <br />
      {categoriesT("falseRepresentation.explanation")}
      <br />
      <br />
      <b>{categoriesT("accountTakeover.title")}</b>
      <br />
      {categoriesT("accountTakeover.explanation")}
      <br />
      <br />
      <b>{categoriesT("disabledCookies.title")}</b>
      <br />
      {categoriesT("disabledCookies.explanation")}
      <br />
      <br />
      <b>{categoriesT("networkAnomalies.title")}</b>
      <br />
      {categoriesT("networkAnomalies.explanation")}
    </span>
  );

  return (
    <div>
      <div className={styles.types}>
        <div className={styles.header}>
          <h4 className="fw-600">{dashboardT("threatTypes.title")}</h4>
          <Tooltip mainContent={categoriesT("summary")} extendedContent={toolitipShowMore} />
        </div>
        <div className={`${styles.body} ${pieChartData.length == 1 && styles.flex_column}`}>
          {pieChartData &&
            pieChartData.map((pieData, index) => {
              const title = pieData.data.insight.threatGroup === threatGroups.INVALID_BOT_ACTIVITY ? invalidBotActivityTitle : invalidMaliciousActivity;
              return (
                <div className={styles.threatGroup} key={index + "-pie-chart"}>
                  <div className={styles.chartWrapper}>
                    <div className="inline-block flex">
                      <Chart chartType="PieChart" data={pieData.data} options={pieData.options} width={100} height={100} />
                    </div>
                    <span>
                      {title} - {pieData.data.insight.totalBlocked}
                    </span>
                  </div>
                  <Chart key={index + "-bar-chart"} chartType="BarChart" data={chartsData[pieData.data.insight.threatGroup]}  options={{ 
                    ...chartsOptions,
                    colors: [chartsData[pieData.data.insight.threatGroup]?.color]
                  }} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ThreatTypes;
