import { modalActions } from "./types";

const initialState = {
  modals: []
};

export default function modalReducer(state = initialState, { payload, type }) {
  switch (type) {
    case modalActions.OPEN:
      return { ...state, modals: [payload] }
    case modalActions.CLOSE: 
      return initialState 
    case modalActions.PUSH:
      return { ...state, modals: Array.prototype.concat(state.modals, [payload]) }
    case modalActions.POP:
      if(!state.modals.length)
        return state
      if(state.modals.length === 1)
        return initialState
      return { ...state, modals: state.modals.slice(0, -1) }
    default:
      return state;
  }
}
