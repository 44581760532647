import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActionCreator } from "../store/modules/auth/action-creators";
import { useLocalStorageUser } from "../utils/User";
import { urlHelper } from "./urlHelper";
import { ROUTES } from "../constants/index";
const { getParams } = urlHelper();

const PrivateRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(false);
  const { getToken, setLocalStorageUser } = useLocalStorageUser();
  const user = useSelector(state => state.authReducer.user);
  const noDomains = useSelector(state => state.domainReducer.noDomains);
  const onlyAdmin = ["/administration", "/"];

  const checkRole = location => {
    if (noDomains && location.pathname !== ROUTES.onboarding) history.push(ROUTES.onboarding);
    return user.role === "admin" || !onlyAdmin.includes(location.pathname);
  };
  const handleStatusChange = () => {
    if (!isOnline) setLocalStorageUser(getParams());
    if (getToken()) dispatch(authActionCreator.login());

    setIsOnline(true);
  };
  useEffect(handleStatusChange, [isOnline]);

  return isOnline ? (
    <Route {...rest} render={({ location }) =>
      getToken() ? (checkRole(location) ? children
        : <Redirect to={{ pathname: ROUTES.dashboard, state: { from: location } }} />)
        : <Redirect to={{ pathname: ROUTES.login, state: { from: location } }} />
      }
    />
  ) : (
    "Loading..."
  );
};

export default PrivateRoute;
