import React from 'react';
import PropTypes from 'prop-types';
import Section from '../../common/Cards/Section';
import styles from "./blockedIps.module.scss"
import Country from '../../common/Country';
import { useTranslation } from 'react-i18next';
import BlockedIPRow from './partials/BlockedIPRow';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersAction } from '../../../store/modules/filters/types';
import { filtersActionCreator } from '../../../store/modules/filters/action-creators';
import HoldTight from '../../Dashboard/HoldTight';
import { TRANSLATION_PREFIX, IP, COUNTRY, DOMAIN, URL, ACTION,BLOCKED_ACTION, MONITORING_ACTION, THREAT_GROUP, THREAT_TYPE, LAST_SEEN, INTERSECTION_OPTIONS, BLOCKED_IPS } from './constants';
import { blocksActionCreator } from '../../../store/modules/blocks/action-creators';

const rowCSS = { 
  section: styles.blocked_ip,
  body: styles.blocked_ip_body
}

const index = () => {
  const { t: tableHeaderT } = useTranslation("blocksReport", { keyPrefix: TRANSLATION_PREFIX })
  const { t: blocksReportT } = useTranslation("blocksReport")
  const { t: commonT } = useTranslation("common")
  const scrollRef = React.useRef()
  const { blockedIps, total: totalBlockedIps } = useSelector((state) => state.blocksReducer.blocked);
  const dispatch   = useDispatch() 
  const updateOffsetFilterAndFetch = async () => {
    await dispatch(filtersActionCreator.setFilter(null, filtersAction.INCREMENT_OFFSET))
    await dispatch(blocksActionCreator.updateBlockedIps())
  }

  const onIntersected = async ([{isIntersecting}]) => {     
    if(isIntersecting) {
      observer.unobserve(scrollRef.current)
      await updateOffsetFilterAndFetch()
    }    
  }

  const observer = new IntersectionObserver(onIntersected, INTERSECTION_OPTIONS)

  const setObserver = () => {    
    if(scrollRef.current)
      observer.observe(scrollRef.current)
    return () => {
      if(scrollRef.current) observer.unobserve(scrollRef.current)
    }
  }  

  const listFooter = () => totalBlockedIps == blockedIps?.length ? <div className={styles["no-data"]}>{commonT("noMoreData")}</div> : <HoldTight showTitle={false} className={styles['hold-tight']}/>

  useEffect(() => {
    if(blockedIps && blockedIps.length && (blockedIps.length < totalBlockedIps)) { return setObserver() }
  }, [blockedIps])

  return ( 
    <div className={styles.wrapper}>   
      <div className={styles.title}>{ blocksReportT(BLOCKED_IPS, { total: totalBlockedIps }) }</div>
      { blockedIps?.map((blockedIP, index) => 
        <Section
          key={index}
          css={rowCSS}
          body={
            <>
            <section className={styles.monitored_status}>
                <BlockedIPRow title={ACTION} value={blockedIP.monitored? MONITORING_ACTION: BLOCKED_ACTION}/> 
              </section> 
              <section className={styles.blocked_ip_details}>
                <BlockedIPRow title={tableHeaderT(IP)} value={blockedIP[IP]} css={{title: styles.blocked_ip_title}}/>
                <BlockedIPRow title={commonT(COUNTRY)} value={<Country country={blockedIP[COUNTRY]} css={styles[COUNTRY]} height={20} width={20}/>} css={{title: styles.blocked_ip_title}}/>              
                <BlockedIPRow title={commonT(DOMAIN)} value={blockedIP[DOMAIN]} css={{ value: styles.ellipsis, title: styles.blocked_ip_title}}/>
                <BlockedIPRow title={tableHeaderT(THREAT_GROUP)} value={blockedIP[THREAT_GROUP]} css={{title: styles.blocked_ip_title}}/>
                <BlockedIPRow title={tableHeaderT(THREAT_TYPE)} value={blockedIP[THREAT_TYPE]} css={{title: styles.blocked_ip_title}}/>
                <BlockedIPRow title={tableHeaderT(LAST_SEEN)} value={blockedIP[LAST_SEEN]} css={{title: styles.blocked_ip_title}}/>              
              </section>     
              <section className={styles.blocked_ip_url}>
                <BlockedIPRow title={tableHeaderT(URL)} value={blockedIP.url} css={{ value: `${styles.blocked_ip_url_value} ${styles.ellipsis}`}}/> 
              </section>                                      
            </>       
          }
        />
      )}
      <div ref={scrollRef}>{ listFooter() }</div>  
    </div>
  );
};

index.propTypes = {
  blockedIps: PropTypes.arrayOf(PropTypes.object)
};

export default index;