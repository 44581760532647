import { useSelector } from "react-redux";
import { summaryAction } from "./types";

const initialSummary = {
  totalBlocked: 0,
  invalidRate: 0,
  protectedValidVisits: 0,
  protectedValidVisitsPercentage: 0,
  whiteListed: 0,
  whiteListedPercentage: 0,
  domain: "",
  bzActive: false,
  isMonitoring: false,
  bzPluginLatestVersion: false
};
const initialState = {
  summary: {
    domainOrNetworkIDHasData: false,
    fromDate: "",
    toDate: "",
    summary: { summary: [initialSummary], total: initialSummary },
    datedSummaries: [],
    monthlySummaries: [],
    insights: [],
    threatTypes: [],
    blocksByCountry: []
  },
  noData: false
};

export default function summaryReducer(state = initialState, { type, payload }) {
  switch (type) {
    case summaryAction.SET_SUMMARY:
      const summary = payload || { ...initialState.summary };
      summary.threatTypes = payload.insights.map(insight => ({ ...insight }));
      if (!summary.summary.summary) summary.summary.summary = { ...initialState.summary.summary };
      else {
        const insightsGrouped = [];
        insightsGrouped.totalBlocked = 0;
        summary.insights.forEach(insight => {
          let currGroup = null;
          insightsGrouped.forEach(insightGrouped => {
            if (insight.threatGroup === insightGrouped.threatGroup) {
              currGroup = insightGrouped;
              return;
            }
          });
          if (!currGroup) insightsGrouped.push(insight);
          else {
            currGroup.totalBlocked += insight.totalBlocked;
            currGroup.invalidRate += insight.invalidRate;
          }
          insightsGrouped.totalBlocked += insight.totalBlocked;
        });
        summary.insights = insightsGrouped;
      }
      return { ...state, summary, noData: !payload?.domainOrNetworkIDHasData };
    default:
      return state;
  }
}
