import { generalAction } from "./types";

const isMobileResolution = () => {
  return window.innerWidth <= 760;
};

const initialState = {
  isLoading: false,
  updatedTime: 0,
  error: "",
  sidebarIsOpen: !isMobileResolution(),
  isMobileResolution: isMobileResolution(),
  splitConfig: null
};

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case generalAction.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case generalAction.SET_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case generalAction.SET_UPDATED_TIME:
      return { ...state, updatedTime: action.payload };
    case generalAction.ADD_A_MINUTE:
      return { ...state, updatedTime: state.updatedTime + 1 };
    case generalAction.SET_SIDEBAR:
      return { ...state, sidebarIsOpen: action.payload };
    case generalAction.SET_CONFIG:
      return { ...state, splitConfig: action.payload };
    default:
      return state;
  }
}
