import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import React, { Suspense } from "react";
import Sidebar from "./components/Layout/Sidebar";
import View from "./components/Layout/View/index";
import PrivateRoute from "./utils/PrivateRoute";
import { store } from "./store/store";
import Login from "./views/Login";
import ModalRoot from "./components/common/modals/ModalRoot";
import Impersonation from "./views/Impersonation";
import LoaderWrapper from "./components/Loader/LoaderWrapper";
import SplitWrapper from "./services/splitIO/init";

const App = () => {
  return (
    <Provider store={store}>
      <SplitWrapper>
        <Router>
          <Suspense fallback={<LoaderWrapper fullScreen />}>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/admin-login">
                <Impersonation />
              </Route>
              <PrivateRoute path="/">
                <div className="App">
                  <div style={{ display: "flex", width: "100vw" }}>
                    <Sidebar />
                    <View />
                  </div>
                </div>
                <div className="modal">
                  <ModalRoot />
                </div>
              </PrivateRoute>
            </Switch>
          </Suspense>
        </Router>
      </SplitWrapper>
    </Provider>
  );
};

export default App;
