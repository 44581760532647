import styles from "./domain-status.module.scss";

const DomainStatusCol = ({ label, onClick, buttonText, style }) => {
  return (
    <div>
      <span className={style} />
      <label>{label}</label>
      <button className={styles.domainStatusButton} onClick={onClick}>
        {buttonText}
      </button>
    </div>
  );
};

export default DomainStatusCol;
