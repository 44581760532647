import Flag from "react-world-flags";
import { getCountry } from "../../services/countriesService"
import { PropTypes } from "prop-types"

const Country = ({ country, height = 16, width= 24, css }) => 
  <span className={ `flex ${css}` }>
    <Flag code={country} height={height} width={width} />
    <span className="ml-5">{getCountry(country)}</span>
  </span>

Country.propTypes = {
  country: PropTypes.string.isRequired
}

export default Country