import { Trash2, X } from "react-feather";
import Modal from "../../common/modals/Modal";
import ModalBody from "../../common/modals/ModalBody";
import React from "react";
import ModalService from "../../../services/ModalService";
import styles from "./deleteModal.module.scss";

const DeleteModal = ({ deleteDomain, domain }) => {
  const closeModal = () => {
    ModalService.close(DeleteModal);
  };
  return (
    <Modal>
      <ModalBody bodyClassName={styles.modalBody} bodyStyle={{ height: "183px" }}>
        <div className={styles.modalHeader}>
          <p className="inline-block float-left bold">Delete Domain</p>
          <X size={16} className={styles.closeButton} onClick={closeModal} />
        </div>
        <p className={styles.title}>Bot Zapping protection for <span className="bold">{domain.domain}</span> will be stopped.</p>
        <div>
          <button className="m-15 position-absolute bottom-right cursor-pointer" onClick={() => deleteDomain(domain)}>
            <Trash2 size={16} style={{ marginRight: ".25rem" }} />
            Yes, delete
          </button>
          <span className={`${styles.backButton} position-absolute color-base cursor-pointer`} onClick={closeModal}>
            Cancel
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
