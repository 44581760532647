import { useAxiosInstance } from "./axiosInstance";
const instance = useAxiosInstance().instance;

export const tagsApi = {
  getTags() {
    return instance().get(`/tags`);
  },
  addTags(tags) {
    return instance().post(`/tags`, tags);
  },
  updateTagMonitoring(tagParams){
    return instance().post(`/tags/monitoring`, tagParams);
  },
  deleteTags(tags) {
    return instance().delete(`/tags`, { data: tags });
  },
  getSecretKey() {
    return instance().get("/");
  }
};
