import { blocksAction } from "./types";
import { format, utcToZonedTime } from "date-fns-tz";
import { DDMMYYYYHHMMSS, UTC } from "../../../constants";
import { isValid, parseISO, isWithinInterval, isAfter } from "date-fns";

const GB = "GB";
const UK = "UK";

const initialState = {
  blocked: {
    total: 0,
    blockedIps: []
  }
};

const isMonitored = (domain, lastClickDate, botzaDomains) => {
  const monitoringIntervals = botzaDomains.find(domainObj => domainObj.domain === domain)?.monitoringIntervals;
  return (
    !!monitoringIntervals?.length &&
    monitoringIntervals.some(({ startDate, endDate }) => {
      let result = false;
      const isoStartDate = parseISO(startDate);
      if (isValid(isoStartDate)) {
        const isoEndDate = parseISO(endDate);
        if (isValid(isoEndDate)) {
          result = isWithinInterval(lastClickDate, {
            start: isoStartDate,
            end: isoEndDate
          });
        } else {
          result = isAfter(lastClickDate, isoStartDate);
        }
      }
      return result;
    })
  );
};

const mapBlockedIps = (blockedIps, botzaDomains) =>
  blockedIps.map(item => {
    item.lastClick = item.lastClick || item.lastSeen;
    item.country = item.country === UK ? GB : item.country;
    const lastClickDate = parseISO(item.lastClick);
    if (isValid(lastClickDate)) {
      item.lastClick = format(utcToZonedTime(lastClickDate, UTC), DDMMYYYYHHMMSS, { timeZone: UTC });
    }
    let pathname;
    try {
      pathname = new URL(item.url).pathname;
    } catch (error) {
      pathname = item.url;
    }
    const { ip, threatGroup, threatType, country, domain } = item;
    const monitored = isMonitored(domain, lastClickDate, botzaDomains);
    return {
      ip,
      threatGroup,
      threatType,
      country,
      domain,
      lastSeen: item.lastClick,
      url: (item.urlQuery && pathname + "?" + item.urlQuery) || pathname || "/",
      monitored
    };
  });

export default function blocksReducer(state = initialState, action) {
  switch (action.type) {
    case blocksAction.SET_BLOCKS:
      return {
        ...state,
        blocked: {
          total: action.payload.total,
          blockedIps: mapBlockedIps(
            action.payload.offset === 0 ? action.payload.blockedIps : [...state.blocked.blockedIps, ...action.payload.blockedIps],
            action.payload.botzaDomains
          )
        }
      };
    case blocksAction.SET_SHOW_WARNING:
      return { ...state, blocked: { ...state.blocked, showWarning: action.payload } };
    default:
      return state;
  }
}
