import { tagsAction } from "./types";

const initialState = {
  tags: [],
  secretKey: null,
  domainsSelect: [],
  currentDomain: null,
  currentTag: null
};

export default function tagsReducer(state = initialState, action) {
  switch (action.type) {
    case tagsAction.SET_TAGS:
      return {
        ...state,
        tags: action.payload
      };
    case tagsAction.SET_SECRET_KEY:
      return {
        ...state,
        secretKey: action.payload
      };
    case tagsAction.SET_DOMAIN_SELECT:
      return {
        ...state,
        domainsSelect: action.payload
      };
    case tagsAction.SET_CURRENT_DOMAIN:
      return {
        ...state,
        currentDomain: action.payload
      };
    case tagsAction.SET_CURRENT_TAG:
      return {
        ...state,
        currentTag: action.payload
      };
    default:
      return state;
  }
}
