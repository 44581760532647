import React     from "react";
import { Chart } from "react-google-charts";
import styles    from "./charts.module.scss";

const options = {
    colorAxis: { colors: ["#FE9345", "#FF4E83"] },
    backgroundColor: "#EFEFEF",
    datalessRegionColor: "#F5F5F5",
};

const Heatmap = ({ data }) => {
    return (
        <div className={ styles.heatmap }>
            <Chart
                chartType="GeoChart"
                width="100%"
                height="480px"
                data={ data }
                options={ options }
            />
        </div>
    )
}

export default Heatmap;

