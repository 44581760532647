export const domainsAction = {
  SET_BOTZA_DOMAINS: "SET_BOTZA_DOMAINS",
  ADD_DOMAIN: "ADD_DOMAIN",
  UPDATE_DOMAINS: "UPDATE_DOMAINS",
  FILTER_DOMAIN: "FILTER_DOMAIN",
  SET_CHECKED_DOMAIN: "SET_CHECKED_DOMAIN",
  SORT_DOMAINS: "SORT_DOMAINS",
  FILTER_BOTZA_DOMAIN: "FILTER_BOTZA_DOMAIN",
  DELETE_DOMAIN: "DELETE_DOMAIN",
  SET_SELECTED_DOMAIN: "SET_SELECTED_DOMAIN",
  ADD_SELECTED_DOMAIN: "ADD_SELECTED_DOMAIN",
  SET_NO_DOMAINS: "SET_NO_DOMAINS",
  SET_DOMAINS_LIMITS: "SET_DOMAINS_LIMITS",
};
