import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertTriangle } from "react-feather";
import { tagsActionCreator } from "../../../store/modules/tags/action-creators";
import { PLUGIN_URL, ROUTES, WORDPRESS_PLUGIN_PARTIAL_URL } from "../../../constants/index";
import ClipboardCopySetup from "../../globals/ClipboardCopySetup";
import Select from "../../globals/Select";
import ModalService from "../../../services/ModalService";
import styles from "./complete_setup.module.scss";
import { useTranslation } from "react-i18next";
import { Copy } from "react-feather";
import ReactTooltip from "react-tooltip";
import { SplitContext } from "@splitsoftware/splitio-react";
import { EVENT_ON, FEATURE_PLUGIN_LINK, UNDEFINED_EVENT_VALUE } from "../../../services/splitIO/helpers";
import { useContext } from "react";
import { TRACK_EVENTS, trackABTesting } from "../../../services/tracking";
import { useHistory } from "react-router-dom";

const CompleteSetup = ({ setBodyHeight, id = "" }) => {
  const dispatch = useDispatch();
  const { client } = useContext(SplitContext);
  const domainLinkTreatment = client.getTreatment(FEATURE_PLUGIN_LINK);
  const showDomainLink = domainLinkTreatment === EVENT_ON;
  let tags = useSelector(state => state.tagsReducer.tags) || [];
  const domainsSelect = useSelector(state => state.tagsReducer.domainsSelect) || [];
  const secretKey = useSelector(state => state.tagsReducer.secretKey);
  const currentDomain = useSelector(state => state.tagsReducer.currentDomain) || [];
  const currentTag = useSelector(state => state.tagsReducer.currentTag);
  const button = useRef(null);
  const showAllEvent = "show-copy-all-tooltip";
  const domains = useSelector(state => state.domainReducer.selectBotzaDomains);
  const history = useHistory();

  const { t } = useTranslation(["onboarding", "common"]);
  useEffect(() => {
    setBodyHeight("auto");

    dispatch(tagsActionCreator.getSecretKey());
    if (id) {
      const domain = domains.find(t => t.searchId == id);
      if (domain) {
        tags = domains.map(domain => ({ hash: domain.hash, apiKey: domain.apiKey, searchId: domain.searchId }));
        domain.value = domain.searchId;
        setSelectDomain(domain);
      }
    }
  }, []);

  useEffect(() => {
    if (domainLinkTreatment !== UNDEFINED_EVENT_VALUE) {
      trackABTesting(TRACK_EVENTS.BZOnboardingPluginPageSeen, domainLinkTreatment);
    }
  }, [domainLinkTreatment]);

  const setSelectDomain = domain => {
    dispatch(tagsActionCreator.setCurrentDomain(domain));
    const selectedTag = tags.find(tag => tag.searchId === domain.value);
    dispatch(tagsActionCreator.setCurrentTag(selectedTag));
  };

  const confirm = () => {
    if (id) {
      history.replace({
        pathname: ROUTES.dashboard,
        search: ""
      });
    }
    ModalService.close();
  };
  const copyHtml = <Copy className={styles.copyIcon} width="14" />;
  const headerClasses = `${styles.header} fw-700`;

  const copyAll = e => {
    var event = new Event(showAllEvent, { bubbles: true });
    e.currentTarget.dispatchEvent(event);
    const copiedValue = { domainKey: currentTag?.hash, secretKey: secretKey, authKey: currentTag?.apiKey };
    navigator.clipboard.writeText(JSON.stringify(copiedValue));
  };

  const background = getComputedStyle(document.documentElement)
    .getPropertyValue("--background-blue")
    .trim();
  const textColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--text-blue")
    .trim();
  const PLUGIN_WORDPRESS_URL = `//${currentDomain.label}${WORDPRESS_PLUGIN_PARTIAL_URL}`;
  return (
    <div className={styles.content}>
      <div className={headerClasses}>
        <p className="flex align-center">
          <AlertTriangle size={16} className="mr-4" />
          <span className="fs-22 fw-700">{t("finishInstallation")}</span>
        </p>
        <div className="flex margin-left-auto">
          <Select minWidth="210px" value={currentDomain} options={domainsSelect} changeHandler={setSelectDomain} />
        </div>
      </div>
      <div className={styles.body}>
        <ol className={styles.list}>
          <li>{t("loginWordPressAccount")}</li>
          {showDomainLink ? (
            <>
              <li>
                {t("installThe", { ns: "common" })}{" "}
                <a href={PLUGIN_WORDPRESS_URL} target="_blank" className="text-color-base cursor-pointer text-decoration-none fs-16">
                  {" "}
                  {t("clickCeaseBotZapingClient")}
                </a>
              </li>
              <li>{t("copyAndPasteNewPluginLink")}</li>
            </>
          ) : (
            <>
              <li>
                {t("downloadThe", { ns: "common" })}{" "}
                <a href={PLUGIN_URL} target="_blank" className="text-color-base cursor-pointer text-decoration-none fs-16">
                  {" "}
                  {t("clickCeaseBotZapingClient")}
                </a>
              </li>
              <li>{t("copyAndPaste")}</li>
            </>
          )}
        </ol>
        {currentTag && secretKey && (
          <>
            <div className={`${styles.codes}`}>
              <div className={`${styles.codesHeader}`}>
                <span className="fs-16 fw-700">{t("installationKeys")}</span>
                <button ref={button} data-tip={t("copied")} data-for={styles.copyTooltip} onClick={copyAll}>
                  <ReactTooltip
                    event={showAllEvent}
                    afterShow={() => {
                      setTimeout(ReactTooltip.hide, 3000);
                    }}
                    className={styles.tooltip}
                    id={styles.copyTooltip}
                    clickable
                    effect="solid"
                    multiline
                    type="dark"
                    backgroundColor={background}
                    textColor={textColor}
                  >
                    {t("copied")}
                  </ReactTooltip>
                  {copyHtml}
                  {t("copyAll")}
                </button>
              </div>
              <div className={styles.codesData}>
                <ClipboardCopySetup dataContainerCSS={styles.setupItem} title={t("domainKey")} copyText={currentTag?.hash} css={styles.clipboard_copy} />
                <ClipboardCopySetup dataContainerCSS={styles.setupItem} title={t("secretKey")} copyText={secretKey} css={styles.clipboard_copy} />
                <ClipboardCopySetup dataContainerCSS={styles.setupItem} title={t("authenticationKey")} copyText={currentTag?.apiKey} css={styles.clipboard_copy} />
              </div>
            </div>
          </>
        )}
      </div>
      <section className={styles.footer}>
        <button className="cursor-pointer" onClick={confirm}>
          {t("okFinished", { ns: "common" })}
        </button>
      </section>
    </div>
  );
};

export default CompleteSetup;
