export const filtersAction = {
  SET_GROUP_FILTER: "SET_GROUP_FILTER",
  SET_COUNTRY_FILTER: "SET_COUNTRY_FILTER",
  SET_DOMAIN_FILTER: "SET_DOMAIN_FILTER",
  SET_PERIOD_FILTER: "SET_PERIOD_FILTER",
  SET_SEARCH_FILTER: "SET_SEARCH_FILTER",
  SET_PERIOD_FILTER_TO_DATE: "SET_PERIOD_FILTER_TO_DATE",
  SET_LAST_3_MONTHS: "SET_LAST_3_MONTHS",
  INCREMENT_OFFSET: "INCREMENT_OFFSET",
  SET_OFFSET: "SET_OFFSET",
  RESET: "RESET"
};
