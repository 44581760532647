import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { emailValidationError } from "../../constants";
import { authActionCreator } from "../../store/modules/auth/action-creators";
import { validators } from "../../utils/validators";
import { useLocalStorageUser } from "../../utils/User";
import { useTranslation } from "react-i18next";

const ImpersonationForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [validateDomainError, setValidateDomainError] = useState("");
  const { validateEmail } = validators();
  const { setLocalStorageUser } = useLocalStorageUser();
  const { t } = useTranslation("common");
  const validateUser = ({ email, imp }) => validateEmail(email) && validateEmail(imp);

  const login = async () => {
    const { error, data } = await dispatch(authActionCreator.getClientIds(user));
    if (data && data.ClientLoginId) {
      const { error, data: response } = await dispatch(authActionCreator.impersonate(user));
      setLocalStorageUser({ id: data.ClientLoginId, token: response?.access_token, language: response?.language, showLogout: true, clientId: data.ClientId });
      dispatch(authActionCreator.login());
      return history.push("/dashboard");
    }
    setValidateDomainError(error?.response?.data?.message || error.message);
  };

  const impersonate = () => {
    if (validateUser(user)) return login();
    setValidateDomainError(emailValidationError);
  };

  return (
    <div className="center pt-100">
      <div className="mb-8">
        <label>
          CC {t("user")} {t("email")} :{" "}
        </label>
        <input onChange={e => setUser({ ...user, email: e.target.value })} value={user.email} type="text" />
      </div>
      <div className="mb-8">
        <label>
          CC {t("user")} {t("admin")} {t("password")} :{" "}
        </label>
        <input onChange={e => setUser({ ...user, password: e.target.value })} value={user.password} type="password" />
      </div>
      <div className="mb-8">
        <label>
          CC {t("client")} {t("email")} :{" "}
        </label>
        <input onChange={e => setUser({ ...user, imp: e.target.value })} onKeyUp={impersonate} value={user.imp} type="text" />
      </div>
      <button onClick={impersonate}>{t("login")}</button>
      <label className="error">{validateDomainError}</label>
    </div>
  );
};

export default ImpersonationForm;
