import React  from 'react';
import styles from "./countries.module.scss";
import TopTen from "./TopTen";
import BlocksHeatmap from "./BlocksHeatmap";

const Countries = () => {
    return (
        <div className={ styles.countries }>
            <div>
                <div>
                    <TopTen />
                </div>
                <div>
                    <BlocksHeatmap />
                </div>
            </div>
        </div>
    )
}

export default Countries;