import { useEffect } from "react";
import { useParams } from "react-router-dom";
import OnboardingModal from "../../components/Onboarding";
import ModalService from "../../services/ModalService";
import Dashboard from "../Dashboard";

const OnBoarding = () => {
  const props = useParams();

  const addModal = () => {
    ModalService.open(OnboardingModal, { props: props });
  };
  useEffect(() => {
    setTimeout(() => {
      addModal();
    }, 100);
  }, []);
  return <Dashboard fakeData />;
};

export default OnBoarding;
