import { useSelector } from "react-redux";
import ImpersonationForm from "../../components/Impersonation";
import LoaderWrapper from "../../components/Loader/LoaderWrapper";

const Impersonation = () => {
  return (
    <LoaderWrapper>
      <ImpersonationForm />
    </LoaderWrapper>
  );
};
export default Impersonation;
