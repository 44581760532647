import React        from 'react';
import styles       from "./insights.module.scss";
import ThreatGroups from "./ThreatGroups";
import ThreatTypes  from "./ThreatTypes"
import { useTranslation } from 'react-i18next';

const Insights = () => {
  const { t } = useTranslation("dashboard", {keyPrefix: "insights"});
  return (
    <div className={ styles.insights }>
        <div>
            <div className={styles.threat_groups_container}>
                <ThreatGroups />
            </div>
            <div className={styles.threat_types_container}>
                <ThreatTypes />
            </div>
        </div>
    </div>
  )
}

export default Insights;